<template>
  <div class="app">
    <b-loading
      :is-full-page="true"
      :active="showSpinner"
      :can-cancel="false"
    ></b-loading>
    <Navbar :show-menu-items-and-logout-button="false"></Navbar>
    <section class="pl-2 pr-2">
      <div class="columns m-0 pb-0">
        <div class="column mb-0 mt-0 pb-0 pt-0 is-half is-offset-one-quarter">
          <section class="hero">
            <ValidationObserver ref="observer" v-slot="{}">
              <section
                class="hero p-0 m-0 b-0 is-fullheight-with-navbar is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center"
              >
                <div class="fields-in-smsEnter-from">
                  <ValidationProvider
                    mode="lazy"
                    :rules="smsCodeRuleString"
                    :name="$t('formsFileds.smsCode')"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      expanded
                      custom-class="custom-class-for-form-field-on-smsEnter-page"
                      :label="$t('login.inputCodeFromSmsCaption')"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-input
                        :disabled="disableSMSEnterField"
                        @input="smsCodeInputHandler"
                        size="is-medium"
                        type="tel"
                        inputmode="tel"
                        v-model="smsCode"
                      ></b-input>
                    </b-field>
                    <div class="mt-0 pb-0 has-text-centered is-size-7">
                      <span>{{ $t("login.yourPhoneNumbeCaption") }}&nbsp;</span
                      ><span>{{ phoneNumber }}</span>
                    </div>
                  </ValidationProvider>
                </div>

                <div class="buttons-in-smsEnter-from">
                  <div
                    class="has-text-centered is-size-7 has-text-weight-medium"
                  >
                    <OfferBlock :offerObject="offerFromProfile" />
                  </div>
                  <b-button
                    :disabled="showIntervalInButton !== ''"
                    class="mt-2"
                    :class="{
                      'get-sms-code-button-small-text':
                        showIntervalInButton && currentLocale !== 'en',
                    }"
                    expanded
                    type="is-secondary"
                    @click.stop.prevent="requestCode"
                  >
                    <span
                      >{{
                        showIntervalInButton
                          ? $t("login.sendSMSCodeAgainButtonCaption")
                          : $t("login.sendSMSCodeButtonCaption")
                      }}
                      {{ showIntervalInButton }}</span
                    >
                  </b-button>
                  <b-button
                    class="mt-2"
                    expanded
                    inverted
                    type="is-secondary"
                    @click.prevent.stop="anotherPhoneNumberButtonHandler"
                  >
                    <span>{{
                      $t("login.anotherPhoneNumberButtonCaption")
                    }}</span>
                  </b-button>
                </div>
              </section>
            </ValidationObserver>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { SEND_AGAIN_CODE_INTERVAL } from "../envVarsAndConstants";
import localStorageHelpers from "../localStorageHelpers";
import moment from "moment";
import Navbar from "../components/Navbar";
import OfferBlock from "../components/OfferBlock";
import auth from "../auth/auth";
import helpers from "../helpers";
export default {
  // eslint-disable-next-line
  name: "SMSCodeEnter",
  components: { Navbar, OfferBlock },

  data() {
    return {
      isSpinnerShow: false,
      smsCode: "",
      error: "",
      // loginLoading: false,
      showOfferModal: false,
      codeSendingToAPI: false,
      repeatOTPRequestExecuting: false,
      sendCodeTimestamp: undefined,
      sendAgainCodeTimestamp: undefined,
      nowTimestamp: undefined,
      timer: undefined,
    };
  },
  methods: {
    // eslint-disable-next-line
    handleRepeatOTPSuccessResponseAnswer(response) {
      this.$buefyNotifications.success(
        this.$t("login.smsSendedRepeat", { number: this.phoneNumber })
      );
    },
    handleRepeatOTPAnswerError(err) {
      this.$buefyNotifications.danger(
        helpers.axiosErrorAsStringForDisplay(err)
      );
    },

    handleLoginSuccessResponseAnswer(token) {
      localStorageHelpers.token.saveTokenToLocalStorage(token);
      this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      this.clearSendAgainCodeTimestampInLocalStorage();

      this.$router.push({
        name: "Dashboard",
        params: { profileIdShort: this.$store.getters.getProfileIdShort },
      });
    },
    handleLoginAnswerError(err) {
      // console.log(err);
      if (err.response.status === 400) {
        this.$buefyNotifications.warning(this.$t("errors.wrongSMSCode"));
        return;
      }
      if (
        err.response.status === 500 &&
        err.response.data?.message &&
        (err.response.data?.message.includes("otp expire") ||
          err.response.data?.message.includes("otp not found"))
      ) {
        this.$buefyNotifications.info(this.$t("login.smsCodeExpire"));
        this.smsCode = "";
        this.sendAgainCodeTimestamp = this.nowTimestamp;
        this.saveSendAgainCodeTimestampToLocalStorage();
        return;
      }
      // this.$buefyNotifications.danger(err.message);
      this.$buefyNotifications.danger(
        helpers.axiosErrorAsStringForDisplay(err)
      );
    },
    getPhoneNumberWithoutFirstPlus(phoneNumber) {
      return helpers.getPhoneNumberWithoutFirstPlus(phoneNumber);
    },
    saveSendAgainCodeTimestampToLocalStorage() {
      localStorageHelpers.sendAgainCode.saveSendAgainCodeTimestampToLocalStorage(
        this.sendAgainCodeTimestamp
      );
    },
    loadSendAgainCodeTimestampFromLocalStorage() {
      return localStorageHelpers.sendAgainCode.loadSendAgainCodeTimestampFromLocalStorage();
    },
    clearSendAgainCodeTimestampInLocalStorage() {
      localStorageHelpers.sendAgainCode.clearSendAgainCodeTimestampInLocalStorage();
    },
    initTimer() {
      // console.log(this.loadSendAgainCodeTimestampFromLocalStorage());
      if (!this.loadSendAgainCodeTimestampFromLocalStorage()) {
        this.initTimerForNewInterval();
      } else {
        this.initTimerForExistingInterval(
          this.loadSendAgainCodeTimestampFromLocalStorage()
        );
      }
    },
    initTimerForExistingInterval(sendAgainCodeTimestamp) {
      // console.log("readed ts", sendAgainCodeTimestamp);
      this.sendCodeTimestamp =
        sendAgainCodeTimestamp - SEND_AGAIN_CODE_INTERVAL;
      this.nowTimestamp = Date.now();
      this.sendAgainCodeTimestamp = sendAgainCodeTimestamp;
    },
    initTimerForNewInterval() {
      this.sendCodeTimestamp = Date.now();
      this.nowTimestamp = this.sendCodeTimestamp;
      this.sendAgainCodeTimestamp =
        this.sendCodeTimestamp + SEND_AGAIN_CODE_INTERVAL;
      this.saveSendAgainCodeTimestampToLocalStorage(
        this.sendAgainCodeTimestamp
      );
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.nowTimestamp = Date.now();
      }, 1000);
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    requestCode() {
      this.$refs.observer.reset();
      this.initTimerForNewInterval();
      // console.log("request code");
      const dataForSend = {
        user_name: this.getPhoneNumberWithoutFirstPlus(this.phoneNumber),
      };
      this.repeatOTPRequestExecuting = true;
      auth.repeatOtp(this, dataForSend).then(
        (response) => {
          this.repeatOTPRequestExecuting = false;
          this.handleRepeatOTPSuccessResponseAnswer(response);
        },
        (err) => {
          this.repeatOTPRequestExecuting = false;
          this.handleRepeatOTPAnswerError(err);
        }
      );
    },
    clickShowOfferHandler() {
      this.showOfferModal = true;
    },
    anotherPhoneNumberButtonHandler() {
      this.$refs.observer.reset();
      this.clearSendAgainCodeTimestampInLocalStorage();
      this.$store.commit("clearPhoneNumber");
      localStorageHelpers.phone.clearPhoneInLocalStorage();
      localStorageHelpers.token.clearTokenInLocalStorage();
      this.$router.push({ name: "Login" });
    },
    smsCodeInputHandler() {
      // console.log("changed");
      this.$refs.observer.reset();
      this.submitCode();
    },
    enterWithoutPhoneHandler() {
      this.clearSendAgainCodeTimestampInLocalStorage();
      this.$store.commit("clearPhoneNumber");
      localStorageHelpers.phone.clearPhoneInLocalStorage();
      this.$router.push({ name: "Login" });
    },

    submitCode() {
      this.$nextTick(() => {
        if (this.smsCode.length === this.smsCodeLength) {
          this.$refs.observer.validate().then((isValid) => {
            if (isValid) {
              this.submit();
            }
          });
        }
      });
    },
    submit() {
      // console.log("submited data:");
      const credentials = {
        user_name: this.getPhoneNumberWithoutFirstPlus(this.phoneNumber),
        confirm_code: this.smsCode,
      };
      console.log(credentials);
      this.codeSendingToAPI = true;
      auth.loginApply(this, credentials).then(
        (response) => {
          this.codeSendingToAPI = false;
          this.handleLoginSuccessResponseAnswer(response.data.token);
        },
        (err) => {
          // this.$router.push({
          //   name: "Dashboard",
          //   params: { profileIdShort: this.$store.getters.getProfileIdShort },
          // });
          this.codeSendingToAPI = false;
          this.handleLoginAnswerError(err);
          // console.log(err);
          // this.$buefyNotifications.danger(err.message);
        }
      );
    },
  },
  computed: {
    offerFromProfile() {
      return this.$store.getters.getOfferFromProfile;
    },
    showSpinner() {
      return this.codeSendingToAPI || this.repeatOTPRequestExecuting;
    },
    currentLocale() {
      return this.$i18n.locale;
    },
    phoneNumber() {
      return this.$store.getters.getPhoneNumber;
    },
    smsCodeRuleString() {
      return `required|smsCode:${this.smsCodeLength}`;
    },
    smsCodeLength() {
      return parseInt(this.$store.getters.getSmsCodeLength);
    },
    disableSMSEnterField() {
      return this.codeSendingToAPI;
    },
    showIntervalInButton() {
      if (
        this.nowTimestamp &&
        this.sendAgainCodeTimestamp &&
        this.sendAgainCodeTimestamp - this.nowTimestamp > 0
      ) {
        const duration = this.sendAgainCodeTimestamp - this.nowTimestamp;
        return moment.duration(duration).format("_MS_");
      }
      return "";
    },
  },
  created() {
    if (!this.$store.getters.getPhoneNumber) {
      // console.log('trt enter without phone')
      this.enterWithoutPhoneHandler();
    } else {
      this.initTimer();
      this.startTimer();
    }
  },
  beforeDestroy() {
    this.stopTimer();
  },
};
</script>

<style scoped>
/*.custom-min-height-for-smsEnter-page {*/
/*  min-height: calc(100vh - 5rem);*/
/*  position: relative;*/
/*}*/
.buttons-in-smsEnter-from {
  position: relative;
  bottom: 70px;
  width: 100%;
  max-width: 300px;
}
.fields-in-smsEnter-from {
  position: relative;
  top: 30vh;
  width: 100%;
  max-width: 300px;
}
.offer-text-in-modal {
  max-height: 60vh;
  overflow: auto;
}
</style>
<style>
.custom-class-for-form-field-on-smsEnter-page {
  text-align: center;
}
.get-sms-code-button-small-text {
  font-size: 0.9em !important;
}
</style>
