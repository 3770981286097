import Vue from "vue";
import VueRouter from "vue-router";
import Full from "../containers/Full.vue";
import Login from "../pages/Login";
import SMSCodeEnter from "@/pages/SMSCodeEnter";
import LastFourDigitsLogin from "@/pages/LastFourDigitsLogin";
import DashboardView from "../views/DashboardView";
import DevicesView from "@/views/DevicesView";
import SubscriptionsView from "@/views/SubscriptionsView";
import SessionsView from "@/views/SessionsView";
import SubscriptionActivate from "@/views/SubscriptionActivate";
import SubscriptionCancel from "@/views/SubscriptionCancel";
import localStorageHelpers from "@/localStorageHelpers";
import Page404 from "@/pages/Page404";
import PageNoProfileId from "@/pages/PageNoProfileId";
import PageBadProfileId from "@/pages/PageBadProfileId";
import store from "./../store/index";
import auth from "../auth/auth";
import { extractProfileIdShortFromURL } from "../envVarsAndConstants";
import { getProfileForInBeforeEachRouterUse } from "../api/profile";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "PageNoProfileId",
    component: PageNoProfileId,
  },
  {
    path: "/:profileIdShort/page-bad-profilei-id",
    name: "PageBadProfileId",
    component: PageBadProfileId,
  },
  {
    path: "/:profileIdShort/",
    redirect: "/:profileIdShort/dashboard",
    name: "Home",
    component: Full,
    children: [
      {
        path: "dashboard",
        component: DashboardView,
        name: "Dashboard",
      },
      {
        path: "devices",
        component: DevicesView,
        name: "Devices",
      },
      {
        path: "subscriptions",
        component: SubscriptionsView,
        name: "Subscriptions",
      },
      {
        path: "subscription-activate",
        component: SubscriptionActivate,
        name: "SubscriptionActivate",
      },
      {
        path: "subscription-cancel",
        component: SubscriptionCancel,
        name: "SubscriptionCancel",
      },
      {
        path: "sessions",
        component: SessionsView,
        name: "Sessions",
      },
    ],
  },
  {
    path: "/:profileIdShort/auth",
    name: "Auth",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "sms-code-enter",
        name: "SMSCodeEnter",
        component: SMSCodeEnter,
      },
      {
        path: "last-four-digits-login",
        name: "LastFourDigitsLogin",
        component: LastFourDigitsLogin,
      },
    ],
  },
  {
    path: "/:profileIdShort/*",
    name: "Page404",
    component: Page404,
  },
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "router-link is-active",
  base: process.env.BASE_URL,
  // scrollBehavior: () => ({ y: 0 }),
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit("closeOpenedSnackbarsAndClearOpenedSnackbarsList");
  console.info(`Transition from ${from.name} to ${to.name}`);
  const profileIdShortFromUrl = extractProfileIdShortFromURL();
  console.log("profileIdShortFromUrl", profileIdShortFromUrl);
  const existingRoutes = router.getRoutes().map((route) => {
    const path = route.path.split("/")[route.path.split("/").length - 1];
    if (path !== "page404") {
      return path;
    }
  });
  // console.log(
  //   "availableRoutes.includes(to.params.profileIdShort)",
  //   availableRoutes.includes(to.params.profileIdShort)
  // );
  // console.log("to.params.profileIdShort", to.params.profileIdShort);
  store.commit("setProfileIdShort", to.params.profileIdShort);
  if (
    to.params.profileIdShort !== undefined &&
    existingRoutes.includes(to.params.profileIdShort)
  ) {
    next({ name: "PageNoProfileId" });
    store.commit("clearProfileIdShort");
    return;
  }
  // if (to.name === null) {
  //   next({ name: "Page404" });
  //   store.commit("clearProfileIdShort");
  //   return;
  // }

  const profielIdShortForRoutes = store.getters.getProfileIdShort;
  //смотрим есть ли уже в сторе данные о полном id профиля, и если нет делаем запрос для получения полного id профиля
  if (
    store.getters.getProfileIdFull === undefined &&
    profielIdShortForRoutes &&
    to.name !== "PageBadProfileId"
  ) {
    // console.log('store.getters.getProfileFullID === undefined)', store.getters.getProfileFullID === undefined)
    try {
      // console.log('here!!!!')
      store.commit("setGetProfileRequestExecuting", true);
      const profileFromAPI = await getProfileForInBeforeEachRouterUse(
        profielIdShortForRoutes
      );
      //const profileForTest = { id: "testestes" };
      store.commit("setProfile", profileFromAPI.data);
    } catch (e) {
      console.log(e);
      next({
        name: "PageBadProfileId",
        params: { profileIdShort: profielIdShortForRoutes },
      });
      store.commit("clearProfileIdShort");
      return;
    } finally {
      store.commit("setGetProfileRequestExecuting", false);
    }
  }

  if (
    to.name !== "Login" &&
    to.name !== "SMSCodeEnter" &&
    to.name !== "LastFourDigitsLogin" &&
    to.name !== "Page404" &&
    to.name !== "PageNoProfileId" &&
    to.name !== "Page4042" &&
    to.name !== "PageBadProfileId"
  ) {
    store.commit("setCheckAuthRequestExecuting", true);
    auth.checkAuth().then(
      (response) => {
        if (
          response.data.user_account &&
          Object.keys(response.data.user_account).length > 0
        ) {
          console.warn("Logged In");
          auth.user.authenticated = true;
        } else {
          console.warn("Not Logged");
          auth.user.authenticated = false;
        }
        if (auth.user.authenticated) {
          next();
        } else {
          if (localStorageHelpers.phone.loadPhoneFromLocalStorage()) {
            router.push({
              name: "LastFourDigitsLogin",
              params: { profileIdShort: profielIdShortForRoutes },
            });
          } else {
            router.push({
              name: "Login",
              params: { profileIdShort: profielIdShortForRoutes },
            });
          }
        }
        store.commit("setCheckAuthRequestExecuting", false);
      },
      // eslint-disable-next-line
      (err) => {
        store.commit("setCheckAuthRequestExecuting", false);
        if (localStorageHelpers.phone.loadPhoneFromLocalStorage()) {
          router.push({
            name: "LastFourDigitsLogin",
            params: { profileIdShort: profielIdShortForRoutes },
          });
        } else {
          router.push({
            name: "Login",
            params: { profileIdShort: profielIdShortForRoutes },
          });
        }
      }
    );
  } else {
    if (
      to.name !== "SMSCodeEnter" &&
      to.name !== "Page404" &&
      to.name !== "PageNoProfileId" &&
      to.name !== "PageBadProfileId" &&
      localStorageHelpers.sendAgainCode.loadSendAgainCodeTimestampFromLocalStorage() &&
      localStorageHelpers.phone.loadPhoneFromLocalStorage()
    ) {
      router.push({
        name: "SMSCodeEnter",
        params: { profileIdShort: profielIdShortForRoutes },
      });
    } else {
      next();
    }
  }
});

export default router;
