<template>
  <b-navbar
    class="custom-navbar-position"
    :mobile-burger="showMenuItemsAndLogoutButton"
    :shadow="true"
  >
    <template #brand>
      <b-navbar-item tag="div">
        <router-link :to="{ name: 'Dashboard' }">
          <h5 class="subtitle is-5 has-text-primary">
            {{ $t("navbar.header") }}
          </h5>
        </router-link>
      </b-navbar-item>
      <b-navbar-item tag="div">
        <!--        <LocaleSwitcher v-if="showingMustRedrawingWhenChangeLocaleElements" />-->
        <LocaleSwitcher />
      </b-navbar-item>
    </template>
    <template #start v-if="showMenuItemsAndLogoutButton">
      <b-navbar-item
        tag="router-link"
        :active="false"
        :to="{ name: 'Dashboard' }"
      >
        {{ $t("dashboard.navBarItemCaption") }}
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'Devices' }">
        {{ $t("devices.navBarItemCaption") }}
      </b-navbar-item>
      <!--      <b-navbar-item tag="router-link" :to="{ path: '/subscriptions' }">-->
      <!--        {{ $t("subscriptions.navBarItemCaption") }}-->
      <!--      </b-navbar-item>-->
      <b-navbar-item tag="router-link" :to="{ name: 'Sessions' }">
        {{ $t("sessions.navBarItemCaption") }}
      </b-navbar-item>
    </template>

    <template #end v-if="showMenuItemsAndLogoutButton">
      <b-navbar-item @click="logoutClickHandler" tag="div">
        <div class="buttons">
          <a class="button is-light">
            <strong>{{
              $t("navbar.logoutButtonCaption").toLowerCase()
            }}</strong>
          </a>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import LocaleSwitcher from "./LocaleSwitcher";
import auth from "../auth/auth";
export default {
  // eslint-disable-next-line
  name: "Navbar",
  components: { LocaleSwitcher },
  methods: {
    logoutClickHandler() {
      auth.logout(this);
    },
  },
  props: {
    showMenuItemsAndLogoutButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.custom-navbar-position {
  position: fixed;
  width: 100%;
}
</style>
