var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-loading',{attrs:{"is-full-page":true,"active":_vm.isSubscribeRequestExecuting,"can-cancel":false}}),_c('PhoneNubmerAndSubscriptionStatusBlock'),_c('div',{staticClass:"card pb-1"},[_c('TitleAndBackButton',{staticClass:"pl-2 pt-2"}),(_vm.subscriptionsList.length)?_c('div',[_c('b-carousel',{attrs:{"icon-pack":'mdi',"icon-size":_vm.screenSize.isMobile || _vm.screenSize.isTablet
            ? 'is-small'
            : 'is-medium',"icon-prev":'chevron-left',"icon-next":'chevron-right',"arrowHover":false,"autoplay":false,"arrow":_vm.isOnlyOneSubscriptionInList ? false : true,"indicator":_vm.isOnlyOneSubscriptionInList ? false : true,"repeat":true,"indicator-inside":false},model:{value:(_vm.inCarouselSubscriptionNumberInArray),callback:function ($$v) {_vm.inCarouselSubscriptionNumberInArray=$$v},expression:"inCarouselSubscriptionNumberInArray"}},_vm._l((_vm.subscriptionsList),function(subscription){return _c('b-carousel-item',{key:subscription.id},[_c('section',[_c('section',{staticClass:"mt-2 ml-2 mr-2 hero is-small is-activesubscription"},[_c('div',{staticClass:"hero-body custom-height"},[_c('p',{staticClass:"subtitle is-4 mb-2 has-text-centered"},[_c('b',[_vm._v(_vm._s(subscription.name))])]),_c('p',{staticClass:"has-text-left subscription-price",class:{
                    'subscription-price-mobile':
                      _vm.screenSize.isMobile || _vm.screenSize.isTablet,
                  }},[_c('b',[_vm._v(_vm._s(_vm.$t("subscriptions.price")))]),_vm._v(": "),_c('span',[_vm._v(_vm._s(subscription.amount))]),(_vm.isSubscriptionRecurring(subscription))?_c('span',{staticClass:"has-text-left"},[_vm._v(" "+_vm._s(_vm.getRecurringPeriodWithI18n(subscription)))]):_vm._e()]),_c('p',{staticClass:"mb-2 has-text-left subscription-length",class:{
                    'subscription-length-mobile':
                      _vm.screenSize.isMobile || _vm.screenSize.isTablet,
                  }},[_c('b',[_vm._v(_vm._s(_vm.$t("subscriptions.available")))]),_vm._v(": "),_c('span',{staticClass:"has-text-left"},[_vm._v(_vm._s(_vm.getSessionLengthTime(subscription)))])]),_c('p',{staticClass:"subtitle subscription-description",class:{
                    'subscription-description-mobile':
                      _vm.screenSize.isMobile || _vm.screenSize.isTablet,
                  }},[_c('span',{staticClass:"description-span-wrapper"},[_vm._v(" "+_vm._s(_vm.getSubscriptionDescription(subscription.id))+" ")])])])])])])}),1),(_vm.subscriptionsList.length)?_c('div',{staticClass:"mt-3 mb-5 has-text-centered"},[_c('b-button',{class:!_vm.disableSubscriptionActivateButton &&
            _vm.inCarouselSubscriptionId &&
            _vm.currentSubscriptionId !== _vm.inCarouselSubscriptionId
              ? 'is-activesubscription'
              : '',attrs:{"size":"is-medium","disabled":_vm.disableSubscriptionActivateButton ||
            !_vm.inCarouselSubscriptionId ||
            _vm.currentSubscriptionId === _vm.inCarouselSubscriptionId},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.activateSubscriptionButtonClickHandler(_vm.inCarouselSubscriptionId)}}},[_vm._v(_vm._s(_vm.$t("subscriptions.activateSubscription")))])],1):_vm._e()],1):_c('div',{staticClass:"mt-2 ml-2 mr-2 hero is-small"},[_vm._v(" "+_vm._s(_vm.$t("subscriptions.noSubscriptions"))+" ")]),_c('div',{staticClass:"pl-2 mt-2"},[_c('GoToSessionsButton')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }