import Vue from "vue";
import { API } from "@/envVarsAndConstants";

export const getProfile = (ctx, shortId) => {
  return new Promise((resolve, reject) => {
    const config = {
      params: {
        short_id: shortId,
      },
    };
    Vue.axios.get(`${window.RESTAPI || ""}/${API.PROFILE}`, config).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
};

export const getProfileForInBeforeEachRouterUse = (shortId) => {
  return new Promise((resolve, reject) => {
    const config = {
      params: {
        short_id: shortId,
      },
    };
    Vue.axios.get(`${window.RESTAPI || ""}/${API.PROFILE}`, config).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
};
