<template>
  <div class="app">
    <Navbar :show-menu-items-and-logout-button="false"></Navbar>
    <b-loading
      :is-full-page="true"
      :active="loginRequestExecuting"
      :can-cancel="false"
    ></b-loading>
    <section class="pl-2 pr-2">
      <div class="columns m-0 pb-0">
        <div class="column mb-0 mt-0 pb-0 pt-0 is-half is-offset-one-quarter">
          <section class="hero">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <section
                class="hero p-0 m-0 b-0 is-fullheight-with-navbar is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center"
              >
                <div class="fields-in-last-four-digits-login-from">
                  <ValidationProvider
                    mode="lazy"
                    :rules="lastFourDigitsRuleString"
                    :name="$t('formsFileds.lastFourDigits')"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      expanded
                      custom-class="custom-class-for-form-field-on-last-four-digits-login-page"
                      :label="$t('login.enterLast4digitsOfPhoneNumber')"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <div
                        class="is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-center"
                      >
                        <div
                          class="phone-number-in-form-elements-in-last-four-digits-login-from pr-2"
                        >
                          {{ phoneNumberWithoutLastFourDigits }}
                        </div>
                        <div
                          class="field-in-form-elements-in-last-four-digits-login-from is-flex-direction-column is-justify-content-center is-align-items-center"
                        >
                          <b-input
                            custom-class="custom-class-for-input-last-four-digits-field"
                            :has-counter="false"
                            :maxlength="4"
                            type="tel"
                            inputmode="tel"
                            v-model="phoneNumberLastFourDigits"
                          ></b-input>
                        </div>
                      </div>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="buttons-in-last-four-digits-login-from">
                  <div
                    class="is-flex is-flex-wrap-wrap is-justify-content-space-between"
                  >
                    <div class="button-in-last-four-digits-login-from">
                      <b-button
                        inverted
                        expanded
                        type="is-secondary"
                        @click.prevent.stop="notMyPhoneNumberButtonHandler"
                      >
                        <span>{{
                          $t("login.notMyPhoneNumberButtonCaption")
                        }}</span>
                      </b-button>
                    </div>
                    <div class="button-in-last-four-digits-login-from">
                      <b-button
                        expanded
                        type="is-secondary"
                        @click="handleSubmit(submit)"
                      >
                        <span>{{ $t("login.nextButtonCaption") }}</span>
                      </b-button>
                    </div>
                  </div>
                </div>
              </section>
            </ValidationObserver>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import localStorageHelpers from "../localStorageHelpers";
import auth from "../auth/auth";
import helpers from "../helpers";
export default {
  // eslint-disable-next-line
  name: "LastFourDigitsLogin",
  components: { Navbar },

  data() {
    return {
      isSpinnerShow: false,
      phoneNumberLastFourDigits: "",
      error: "",
      loginLoading: false,
      loginRequestExecuting: false,
    };
  },
  methods: {
    getPhoneNumberWithoutFirstPlus(phoneNumber) {
      return helpers.getPhoneNumberWithoutFirstPlus(phoneNumber);
    },
    handleLoginSuccessAnswer(token) {
      localStorageHelpers.token.saveTokenToLocalStorage(token);
      this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;

      this.$router.push({
        name: "Dashboard",
        params: { profileIdShort: this.$store.getters.getProfileIdShort },
      });
    },
    handleLoginNeedSMSAnswer() {
      this.$buefyNotifications.success(
        this.$t("login.smsSended", { number: this.phoneNumber })
      );
      this.$router.push({
        name: "SMSCodeEnter",
        params: { profileIdShort: this.$store.getters.getProfileIdShort },
      });
    },
    handleLoginAnswerError(err) {
      // console.log(err);
      if (err.response.status === 404) {
        this.$buefyNotifications.warning(this.$t("errors.noUser"));
        this.notMyPhoneNumberButtonHandler();
        return;
      }
      // this.$buefyNotifications.danger(err.message);
      this.$buefyNotifications.danger(
        helpers.axiosErrorAsStringForDisplay(err)
      );
    },
    submit() {
      // console.log("submited data:");
      const credentials = {
        user_name: this.getPhoneNumberWithoutFirstPlus(this.phoneNumber),
        // profileID: this.$store.getters.getProfileIdShort,
      };
      // console.log(credentials);
      this.loginRequestExecuting = true;
      auth.login(this, credentials).then(
        (response) => {
          if (response.status === 204) {
            this.handleLoginNeedSMSAnswer();
          } else {
            // console.log(response);
            this.handleLoginSuccessAnswer(response.data.token);
          }
          this.loginRequestExecuting = false;
        },
        (err) => {
          // this.$router.push({
          //   name: "Dashboard",
          //   params: { profileIdShort: this.$store.getters.getProfileIdShort },
          // });
          this.loginRequestExecuting = false;
          this.handleLoginAnswerError(err);
          // console.log(err);
          // this.$buefyNotifications.danger(err.message);
        }
      );
    },
    notMyPhoneNumberButtonHandler() {
      this.$refs.observer.reset();
      this.$store.commit("clearPhoneNumber");
      localStorageHelpers.phone.clearPhoneInLocalStorage();
      localStorageHelpers.token.clearTokenInLocalStorage();
      this.$router.push({ name: "Login" });
    },
  },
  computed: {
    lastFourDigitsRuleString() {
      return `required|digits:4|lastFourDigits:${this.phoneNumber}`;
    },
    phoneNumber() {
      return this.$store.getters.getPhoneNumber;
    },
    phoneNumberWithoutLastFourDigits() {
      const length = this.$store.getters.getPhoneNumber.length;
      return this.$store.getters.getPhoneNumber.slice(0, length - 4);
    },
  },
  created() {
    if (!this.$store.getters.getPhoneNumber) {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style scoped>
/*.custom-min-height-for-last-four-digits-login-page {*/
/*  min-height: calc(100vh - 5rem);*/
/*  position: relative;*/
/*}*/
.buttons-in-last-four-digits-login-from {
  position: relative;
  bottom: 70px;
  width: 100%;
  max-width: 300px;
}
/*.fields-in-last-four-digits-login-from {*/
/*  position: relative;*/
/*  top: 30vh;*/
/*  width: 100%;*/
/*  max-width: 300px;*/
/*}*/
.button-in-last-four-digits-login-from {
  width: 45%;
  max-width: 200px;
}
.phone-number-in-form-elements-in-last-four-digits-login-from {
  /*width: 45%;*/
  max-width: 200px;
  font-size: 1.1em !important;
}
/*.field-in-form-elements-in-last-four-digits-login-from {*/
/*  width: 30%;*/
/*  max-width: 200px;*/
/*}*/
</style>
<style>
.custom-class-for-form-field-on-last-four-digits-login-page {
  text-align: center;
}
</style>
<style lang="scss">
.custom-class-for-input-last-four-digits-field {
  font-size: 1.1em !important;
  height: 2em !important;
  padding: 0px !important;
  padding-left: 0.2em !important;
}
.fields-in-last-four-digits-login-from {
  position: relative;
  top: 30vh;
  width: 100%;
  max-width: 300px;
  & .help {
    text-align: center !important;
  }
}
.field-in-form-elements-in-last-four-digits-login-from {
  width: 30%;
  max-width: 200px;
  position: relative;
  top: -0.1em;
  & .icon {
    height: 2.2em !important;
  }
}
</style>
