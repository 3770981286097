<template>
  <div class="app">
    <section class="pl-2 pr-2">
      <div class="columns m-0 pb-0">
        <div class="column mb-0 mt-0 pb-0 pt-0 is-half is-offset-one-quarter">
          <section class="hero">
            <section
              class="hero p-0 m-0 b-0 is-fullheight is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            >
              <div class="fields-in-login-from">
                <div class="m-2" v-if="!currentProfileId">
                  {{ $t("page404.titleNoProfileId") }}
                </div>
                <div class="m-2" v-else>
                  {{ $t("page404.titleNotFound") }}
                </div>
                <div class="m-2" v-if="currentProfileId">
                  {{ $t("page404.currentProfileId") }}
                  {{ currentProfileId }}
                  <div>
                    <b-button
                      type="is-text"
                      @click="
                        $router.push({
                          name: 'Dashboard',
                          params: { profileIdShort: currentProfileId },
                        })
                      "
                      class="custom-button pl-0"
                    >
                      {{ $t("page404.toMainPage") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Page404",

  data() {
    return {};
  },
  methods: {},
  computed: {
    currentProfileId() {
      return window.PROFILE_ID_SHORT;
    },
  },
  created() {
    // console.log(this.$route);
  },
  beforeDestroy() {},
};
</script>

<style scoped></style>
