<template>
  <div>
    <PhoneNubmerAndSubscriptionStatusBlock />
    <div class="card pb-1">
      <TitleAndBackButton class="pl-2 pt-2">{{
        $t("devices.header")
      }}</TitleAndBackButton>
      <div class="columns is-mobile m-0 pb-0">
        <div class="column is-12">
          <b-table
            :mobile-cards="false"
            striped
            narrowed
            hoverable
            :data="devicesData"
            :columns="devicesTableColumns"
          >
            <template slot="empty">
              <div class="is-fullwidth has-text-centered">
                {{ $t("general.noData") }}
              </div>
            </template></b-table
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneNubmerAndSubscriptionStatusBlock from "../components/PhoneNubmerAndSubscriptionStatusBlock";
import TitleAndBackButton from "../components/TitleAndBackButton";
export default {
  name: "DevicesView",
  components: { TitleAndBackButton, PhoneNubmerAndSubscriptionStatusBlock },
  computed: {
    devicesData() {
      // чтобы перерисовывалось при сменен локали
      if (this.showingMustRedrawingWhenChangeLocaleElements) {
        if (this.$store.getters.getAccountMacs) {
          return this.$store.getters.getAccountMacs.map((mac) => {
            return {
              mac: mac,
            };
          });
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    devicesTableColumns() {
      // чтобы перерисовывалось при сменен локали
      if (this.showingMustRedrawingWhenChangeLocaleElements) {
        return [
          {
            field: "mac",
            label: "MAC",
            centered: true,
          },
        ];
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped></style>
