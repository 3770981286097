import Vue from "vue";
import { API } from "@/envVarsAndConstants";
import store from "@/store";
import {
  setIsSubscribedAndCurrrentSubscriptionData,
  setIsUnsubscribedAndClearSubscriptionData,
} from "@/api/subscriptions";
// import { getSessions } from "@/api/sessions";
// import helpers from "@/helpers";

export const getAccount = (ctx) => {
  store.commit("setAccountRequestExecuting", true);
  return Vue.axios
    .get(
      `${window.RESTAPI || ""}/${store.getters.getProfileIdFull}/${API.ACCOUNT}`
    )
    .then(
      (response) => {
        // console.log(response);
        store.commit("setAccountRequestExecuting", false);
        const accountFromApi = response.data?.user_account;
        store.commit("setAccount", accountFromApi);
        // setIsSubscribedAndCurrrentSubscriptionId(accountFromApi.subscribe_id);
        setIsSubscribedAndCurrrentSubscriptionData(
          accountFromApi.subscribe_id,
          accountFromApi.create_at_subscribe
        );
      },
      (err) => {
        store.commit("setAccountRequestExecuting", false);
        console.log(err);
        // setIsSubscribedAndCurrrentSubscriptionId();
        setIsUnsubscribedAndClearSubscriptionData();
        // ctx.$buefyNotifications.danger(
        //   helpers.axiosErrorAsStringForDisplay(err)
        // );
        ctx.$buefyNotifications.danger(
          ctx.$t("errors.accountDataLoadingError")
        );
      }
    );
};

// export const getAccountAndThenGetSessions = (ctx) => {
//   store.commit("setAccountRequestExecuting", true);
//   Vue.axios
//     .get(
//       `${window.RESTAPI || ""}/${store.getters.getProfileIdFull}/${API.ACCOUNT}`
//     )
//     .then(
//       (response) => {
//         // console.log(response);
//         store.commit("setAccountRequestExecuting", false);
//         const accountFromApi = response.data?.user_account;
//         store.commit("setAccount", accountFromApi);
//         getSessions(ctx);
//       },
//       (err) => {
//         store.commit("setAccountRequestExecuting", false);
//         console.log(err);
//         // ctx.$buefyNotifications.danger(
//         //   helpers.axiosErrorAsStringForDisplay(err)
//         // );
//         ctx.$buefyNotifications.danger(
//           ctx.$t("errors.accountDataLoadingError")
//         );
//       }
//     );
// };
