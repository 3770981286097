<template>
  <div class="locale-switcher-wrapper">
    <b-dropdown :mobile-modal="true" :expanded="true" aria-role="dialog">
      <template #trigger>
        <span class="flag-icon" :class="`flag-icon-${currentLocale}`" />
      </template>
      <b-dropdown-item
        aria-role="listitem"
        v-for="locale in sortedLocales"
        :key="locale"
        class="p-0 m-0"
        @click="setLocale(locale)"
      >
        <div class="p-0 pt-1 pb-1 m-0 has-text-centered">
          <span
            class="pl-0 pr-0 flag-icon flag-into-dropdown"
            :class="`flag-icon-${locale}`"
          />
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import helpers from "../helpers";
import localStorageHelpers from "../localStorageHelpers";
export default {
  name: "LocaleSwitcher",
  data() {
    return {
      locales: {
        en: "en",
        ru: "ru",
        tj: "tj",
      },
      reverseLocales: {
        en: "en",
        ru: "ru",
        tj: "tj",
      },
      currentLocale: "tj",
    };
  },
  methods: {
    setLocale(locale) {
      if (locale === this.currentLocale) return;
      // localStorage.setItem("LOCALE", this.reverseLocales[locale]);
      localStorageHelpers.locale.saveLocaleToLocalStorage(
        this.reverseLocales[locale]
      );
      // this.$emit("localeSwitcherLocaleSwitched");
      // console.log("locale", locale);
      this.$i18n.locale = locale.toLowerCase();
      window.LOCALE = locale.toLowerCase();
      helpers.changeMomentLocale(locale.toLowerCase());
      this.currentLocale = locale;
      this.$store.commit("setLocaleSwitchedTimeStamp");

      // document.location.reload();
    },
  },
  computed: {
    sortedLocales() {
      const sortedLocales = {};

      Object.keys(this.locales)
        .sort((curr) => {
          if (
            curr ===
            localStorageHelpers.locale
              .loadLocaleFromLocalStorage()
              .toLowerCase()
          ) {
            return 1;
          }
          return -1;
        })
        .forEach((locale) => {
          sortedLocales[locale] = this.locales[locale];
        });

      return sortedLocales;
    },
  },
  mounted() {
    const lsLocale = localStorageHelpers.locale.loadLocaleFromLocalStorage();
    if (lsLocale) {
      this.currentLocale = this.locales[lsLocale.toLowerCase()];
    }
  },
};
</script>

<style lang="scss">
.locale-switcher-wrapper {
  display: inline-block;
  border-radius: 2px;
  font-size: 22px;
  line-height: 0;
  z-index: 9999;
  cursor: pointer;

  .flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;

    &-ru {
      background-image: url("/public/static/img/flags/ru.svg");
    }

    &-tj {
      background-image: url("/public/static/img/flags/tj.svg");
    }

    &-en {
      background-image: url("/public/static/img/flags/gb.svg");
    }
  }
}
</style>
