<template>
  <div class="mb-1">
    <h3 class="subtitle is-3 p-0 m-0 mb-1">
      <span><slot></slot></span>
    </h3>
    <b-button
      type="is-text"
      @click="goBackButtonClickHandler"
      class="custom-button"
    >
      <b-icon
        class="arrow-custom-position-for-back-link"
        icon="arrow-left-bold"
      />
      <span>{{ $t("general.goMainCaption") }}</span>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "TitleAndBackButton",
  methods: {
    goBackButtonClickHandler() {
      // console.log("this.$route", this.$route);
      // console.log("window.history", window.history);
      // console.log("window.history.length", window.history.length);
      // if (this.hasHistory()) {
      //   this.$router.go(-1);
      // } else {
      //   this.$router.push({ name: "Dashboard" });
      // }
      this.$router.push({ name: "Dashboard" });
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
  commputed: {
    hasBackRoute() {},
  },
  created() {
    // console.log(this.$router);
  },
};
</script>

<style scoped>
.goback-div {
  display: table;
}
.goback-div-child {
  display: table-cell;
  vertical-align: middle;
}
.custom-button {
  border: 0;
  padding-left: 0.2em;
  padding-right: 0.2em;
  margin: 0;
}
.arrow-custom-position-for-back-link {
  position: relative;
  top: 0.3em;
}
</style>
