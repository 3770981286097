<template>
  <div v-if="showApp" class="app-background" style="min-height: 100vh">
    <b-loading
      :is-full-page="true"
      :active="
        $store.getters.getCheckAuthRequestExecuting ||
        $store.getters.getGetProfileRequestExecuting
      "
      :can-cancel="false"
    ></b-loading>

    <router-view></router-view>
  </div>
</template>

<script>
import localStorageHelpers from "./localStorageHelpers";
export default {
  name: "App",
  data() {
    return {
      showApp: true,
    };
  },
  computed: {
    localeSwitchedTimeStamp() {
      return this.$store.getters.getLocaleSwitchedTimeStamp;
    },
  },
  created() {
    if (localStorageHelpers.phone.loadPhoneFromLocalStorage()) {
      this.$store.commit(
        "setPhoneNumber",
        localStorageHelpers.phone.loadPhoneFromLocalStorage()
      );
    }
  },
  watch: {
    localeSwitchedTimeStamp() {
      this.$store.dispatch("redrawMustRedrawingElements");
    },
  },
};
</script>

<style lang="scss">
.profileIdShort-for-test {
  position: fixed;
  color: blue;
  z-index: 10000000;
  top: 100px;
  left: 20px;
}
</style>
