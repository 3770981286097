<template>
  <div class="app">
    <section class="pl-2 pr-2">
      <div class="columns m-0 pb-0">
        <div class="column mb-0 mt-0 pb-0 pt-0 is-half is-offset-one-quarter">
          <section class="hero">
            <section
              class="hero p-0 m-0 b-0 is-fullheight is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            >
              <div class="fields-in-login-from">
                <div
                  class="m-2 is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
                >
                  <div>
                    {{ $t("page404.titleNoProfileId") }}
                  </div>
                  <!--                  <div>{{ $t("page404.orBadProfileID") }}</div>-->
                  <div>
                    {{ $t("page404.forExampleCaption") }}
                  </div>
                  <div>
                    {{ exampleAddress }}
                  </div>
                  <div>
                    <!--                    <LocaleSwitcher-->
                    <!--                      v-if="showingMustRedrawingWhenChangeLocaleElements"-->
                    <!--                    />-->
                    <LocaleSwitcher />
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LocaleSwitcher from "../components/LocaleSwitcher";
export default {
  // eslint-disable-next-line
  name: "PageNoProfileId",
  components: { LocaleSwitcher },
  data() {
    return {};
  },
  methods: {},
  computed: {
    currentProfileId() {
      return window.PROFILE_ID_SHORT;
    },
    exampleAddress() {
      return `${window.location.origin}/PROFILE_ID/`;
    },
  },
  created() {
    // console.log(this.$route);
  },
  beforeDestroy() {},
};
</script>

<style scoped></style>
