<template>
  <div>
    <PhoneNubmerAndSubscriptionStatusBlock />
    <div class="card pb-1">
      <TitleAndBackButton class="pl-2 pt-2">{{
        $t("subscriptions.header")
      }}</TitleAndBackButton>
    </div>
  </div>
</template>

<script>
import PhoneNubmerAndSubscriptionStatusBlock from "../components/PhoneNubmerAndSubscriptionStatusBlock";
import TitleAndBackButton from "../components/TitleAndBackButton";
export default {
  name: "SubscriptionsView",
  components: { TitleAndBackButton, PhoneNubmerAndSubscriptionStatusBlock },
};
</script>

<style scoped></style>
