import Vue from "vue";
import localStorageHelpers from "@/localStorageHelpers";
import { API } from "@/envVarsAndConstants";
import store from "@/store";
import helpers from "@/helpers";

const checkLogin = () => {
  return new Promise((resolve, reject) => {
    // const query = { action: "R" };
    Vue.axios
      .get(
        `${window.RESTAPI || ""}/${store.getters.getProfileIdFull}/${
          API.CHECK_AUTH
        }`
      )
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
  });
};

export default {
  user: {
    authenticated: false,
  },
  login(context, creds) {
    return Vue.axios.post(
      `${window.RESTAPI || ""}/${store.getters.getProfileIdFull}/${API.LOGIN}`,
      creds
    );
  },

  loginApply(context, creds) {
    return Vue.axios.post(
      `${window.RESTAPI || ""}/${store.getters.getProfileIdFull}/${
        API.APPLY_SIGN_IN
      }`,
      creds
    );
  },

  repeatOtp(context, creds) {
    return Vue.axios.post(
      `${window.RESTAPI || ""}/${store.getters.getProfileIdFull}/${
        API.REPEAT_OTP
      }`,
      creds
    );
  },

  async logout(ctx) {
    const profielIdShortForRoutes = ctx.$store.getters.getProfileIdShort;
    try {
      store.commit("setLogoutRequestExecuting", true);
      await ctx.axios.get(
        `${window.RESTAPI || ""}/${store.getters.getProfileIdFull}/${
          API.LOGOUT
        }`,
        {}
      );
      store.commit("setLogoutRequestExecuting", false);
      //localStorage.removeItem("token");
      localStorageHelpers.token.clearTokenInLocalStorage();
      ctx.axios.defaults.headers.common.Authorization = "";
      this.user.authenticated = false;
      ctx.$store.commit("clearPhoneNumber");
      localStorageHelpers.phone.clearPhoneInLocalStorage();
      ctx.$router.push({
        name: "Login",
        params: { profileIdShort: profielIdShortForRoutes },
      });
    } catch (e) {
      store.commit("setLogoutRequestExecuting", false);
      ctx.$buefyNotifications.danger(helpers.axiosErrorAsStringForDisplay(e));
    }
  },
  checkAuth() {
    // const jwt = localStorage.getItem("token");
    const jwt = localStorageHelpers.token.loadTokenFromLocalStorage();
    if (!Vue.axios.defaults.headers.common.Authorization && jwt) {
      Vue.axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    }
    // let checkAuthQuery = null;
    return checkLogin();
  },
};
