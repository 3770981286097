<template>
  <div>
    <div v-if="active" class="loading-overlay is-active overlay-text-block">
      {{ text }}
    </div>
    <b-loading
      :active="active"
      :animation="animation"
      :is-full-page="isFullPage"
      :can-cancel="canCancel"
      :onCancel="onCancel ? onCancel : undefined"
      @close="$emit('close')"
      ><div class="loading-overlay is-active">
        <div class="loading-background"></div>
        <div class="loading-icon"></div></div
    ></b-loading>
  </div>
</template>

<script>
export default {
  name: "BLoadingWithText",
  props: {
    active: {
      default: false,
      type: Boolean,
    },
    animation: {
      default: "fade",
      type: String,
    },
    isFullPage: {
      default: true,
      type: Boolean,
    },
    canCancel: {
      default: false,
      type: Boolean,
    },
    onCancel: {
      type: Function,
    },
    text: {
      default: "",
      type: String,
    },
  },
};
</script>

<style scoped>
.overlay-text-block {
  opacity: 0.6;
  position: absolute;
  top: 5.5em;
  font-size: 0.8em;
  z-index: 30;
}
</style>
