import { API } from "@/envVarsAndConstants";

export const setAxiosInterceptors = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error) {
        // console.log("interceptors error", error);
        // если прилетает ошибка авторизации (401) из какой-нибудь ручки,
        // кроме ручки предназначеной для проверки на авторазацию при смене роута,
        // и если там есть текст "token is expired" релоадим страницу,
        // чтообы после этого отобразлися экран для ввода телефона или 4 последних цифр для логина,
        // чтобы получить новый токен
        const urlAsArray = error.config.url.split("/");
        const apiHandler = urlAsArray[urlAsArray.length - 1];
        if (apiHandler !== API.CHECK_AUTH && error.response?.status === 401) {
          if (
            error.response?.data?.message &&
            error.response?.data?.message.includes("token is expired")
          ) {
            // console.log('token is expired')
            // confirm("test");
            document.location.reload();
          }
        }
      }
      return Promise.reject(error);
    }
  );
};
