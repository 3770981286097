const momentDurationLocaleEN = {
  durationLabelsStandard: {
    S: "millisecond",
    SS: "milliseconds",
    s: "second",
    ss: "seconds",
    m: "minute",
    mm: "minutes",
    h: "hour",
    hh: "hours",
    d: "day",
    dd: "days",
    w: "week",
    ww: "weeks",
    M: "month",
    MM: "months",
    y: "year",
    yy: "years",
  },
  durationLabelsShort: {
    S: "msec",
    SS: "msecs",
    s: "sec",
    ss: "secs",
    m: "min",
    mm: "mins",
    h: "hr",
    hh: "hrs",
    d: "dy",
    dd: "dys",
    w: "wk",
    ww: "wks",
    M: "mo",
    MM: "mos",
    y: "yr",
    yy: "yrs",
  },
  durationTimeTemplates: {
    // HMS: "h:mm:ss",
    HMS: "h [h]. m [m]. ss [s]",
    HM: "h:mm",
    MS: "m [m] ss [s]",
  },
  durationLabelTypes: [
    { type: "standard", string: "__" },
    { type: "short", string: "_" },
  ],
  durationPluralKey: function (token, integerValue, decimalValue) {
    // Singular for a value of `1`, but not for `1.0`.
    if (integerValue === 1 && decimalValue === null) {
      return token;
    }

    return token + token;
  },
};
export default momentDurationLocaleEN;
