import Vue from "vue";
import { API } from "@/envVarsAndConstants";
import store from "@/store";
import helpers from "@/helpers";
import { getAccount } from "@/api/account";

export const getSessions = async (ctx) => {
  let accountId = store.getters.getAccountId;

  if (!accountId) {
    try {
      // console.log("account data loading");
      await getAccount(ctx);
      accountId = store.getters.getAccountId;
    } catch (e) {
      ctx.$buefyNotifications.warning(helpers.axiosErrorAsStringForDisplay(e));
    }
  }

  const config = {
    params: {
      account: accountId,
    },
  };
  store.commit("setSessionsRequestExecuting", true);
  Vue.axios
    .get(
      `${window.RESTAPI || ""}/${store.getters.getProfileIdFull}/${
        API.SESSIONS
      }`,
      config
    )
    .then(
      (response) => {
        store.commit("setSessionsRequestExecuting", false);
        const sessionsFromApi =
          response.data?.sessions === null ? [] : response.data?.sessions;
        store.commit("setSessions", sessionsFromApi);
      },
      (err) => {
        store.commit("setSessionsRequestExecuting", false);
        console.log(err);
        ctx.$buefyNotifications.danger(
          ctx.$t("errors.sessionsDataLoadingError")
        );
      }
    );
};
