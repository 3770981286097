<template>
  <div>
    <PhoneNubmerAndSubscriptionStatusBlock />
    <div class="card pb-1">
      <div class="columns is-mobile m-0 pb-0">
        <div class="column is-7 mb-3">
          <CurrentDeviceBlock :current-device="currentDevice" />
        </div>
        <div class="column is-5">
          <div
            class="is-fullwidth is-flex is-justify-content-end is-align-items-start"
          >
            <b-button
              type="is-text"
              @click="$router.push({ name: 'Devices' })"
              class="custom-button-for-link-on-dashboard"
            >
              <span>{{ $t("devices.otherDevicesCaption") }}</span>
              <b-icon
                class="arrow-custom-position-for-other-devices"
                icon="arrow-right-bold"
              />
            </b-button>
          </div>
        </div>
      </div>
      <section class="mt-2 ml-2 mr-2 hero is-small is-info">
        <a
          class="hero-body banner-custom-styles"
          target="_blank"
          :href="linkForAboutBanner"
        >
          <p class="title is-4">
            {{ $t("subscriptions.aboutSubscriptionCaption") }}
          </p>
          <div class="subtitle banner-custom-border">
            <a
              >{{ $t("general.goToBannerCaption") }}
              <b-icon
                class="banner-arrow-custom-position"
                icon="arrow-right-bold"
            /></a>
          </div>
        </a>
      </section>
      <div class="columns is-mobile m-0 pb-0 mt-2">
        <div class="column is-12 buttons-block-wrapper">
          <BLoadingWithText
            :is-full-page="false"
            :active="isNumberChecking && !showOverAllAppSpinner"
            :can-cancel="false"
            :text="$t('subscriptions.numberCheckExecuting')"
          ></BLoadingWithText>
          <div
            v-if="!isMegafonNumber && !isNumberChecking"
            class="is-fullwidth has-text-centered is-flex is-flex-direction-column is-justify-content-center is-align-items-center only-for-megafon-subscribers-overlay"
          >
            <div
              class="has-text-centered only-for-megafon-subscribers-text-block-in-overlay"
            >
              <b-tag :rounded="true" type="is-medium is-warning is-light">{{
                $t("subscriptions.onlyForMegafonSubscribers")
              }}</b-tag>
            </div>
          </div>
          <div
            class="is-fullwidth is-flex is-flex-direction-column is-align-items-center"
          >
            <b-button
              :disabled="disableSubscriptionActivateButton"
              @click="$router.push({ name: 'SubscriptionActivate' })"
              expanded
              :class="
                !disableSubscriptionActivateButton || !isMegafonNumber
                  ? 'is-activesubscription'
                  : ''
              "
              >{{ $t("subscriptions.activateSubscription") }}</b-button
            >

            <b-button
              :disabled="disableSubscriptionCancelButton"
              @click="$router.push({ name: 'SubscriptionCancel' })"
              expanded
              :class="
                !disableSubscriptionCancelButton
                  ? 'is-notactivesubscription'
                  : ''
              "
              class="mt-1"
              >{{ $t("subscriptions.cancelSubscription") }}</b-button
            >
          </div>
        </div>
      </div>
      <div class="pl-2 mt-2">
        <GoToSessionsButton />
      </div>
    </div>
  </div>
</template>

<script>
import PhoneNubmerAndSubscriptionStatusBlock from "../components/PhoneNubmerAndSubscriptionStatusBlock";
import GoToSessionsButton from "../components/GoToSessionsButton";
import CurrentDeviceBlock from "../components/CurrentDeviceBlock";
import BLoadingWithText from "../components/BLoadingWithText";
export default {
  name: "DashboardView",
  // eslint-disable-next-line
  components: {
    PhoneNubmerAndSubscriptionStatusBlock,
    GoToSessionsButton,
    CurrentDeviceBlock,
    BLoadingWithText,
  },
  methods: {},
  computed: {
    linkForAboutBanner() {
      return window.linkForAboutBanner;
    },
    isNumberChecking() {
      return this.$store.getters.getCheckNumberRequestExecuting;
    },
    disableSubscriptionActivateButton() {
      if (this.isSubscribed || !this.isMegafonNumber) {
        return true;
      }
      return false;
    },
    disableSubscriptionCancelButton() {
      if (!this.isSubscribed || !this.isMegafonNumber) {
        return true;
      }
      return false;
    },
    currentDevice() {
      return {
        mac: this.$store.getters.getAccountMacs?.length
          ? this.$store.getters.getAccountMacs[
              this.$store.getters.getAccountMacs?.length - 1
            ]
          : "",
      };
    },
  },
};
</script>

<style scoped>
.buttons-block-wrapper {
  position: relative;
}
.only-for-megafon-subscribers-overlay {
  opacity: 0.9;
  position: absolute;
  backdrop-filter: blur(1px);
  z-index: 2;
  width: calc(100% - 1.5rem);
  height: calc(100% - 1.5rem);
  padding: 0;
  margin: 0;
  text-shadow: 0.5px 0.5px darkgrey;
}
.only-for-megafon-subscribers-text-block-in-overlay {
}
.banner-arrow-custom-position {
  position: relative;
  top: 0.15em;
}
.banner-custom-styles {
  cursor: pointer;
}
.arrow-custom-position-for-other-devices {
  position: relative;
  top: 0.35em;
}
</style>
