<template>
  <section class="">
    <div class="columns is-mobile m-0 pb-0">
      <div class="column is-6">
        <div
          class="is-fullwidth is-flex is-justify-content-start is-align-items-start"
        >
          <div class="is-size-4">
            {{ phoneNumberMasked }}
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div
          v-if="showingMustRedrawingWhenChangeLocaleElements"
          class="is-fullwidth is-flex is-justify-content-end is-align-items-start"
        >
          <b-tag
            v-if="isSubscribed"
            size="is-large"
            class="is-activesubscription default-cursor"
            rounded
            ><div
              class="inner-block-for-subscription-status-tag"
              :class="{
                'is-subscription-active-inner-block-when-subscriptionFromDate':
                  subscriptionFromDate,
              }"
            >
              <b-tooltip
                type="is-dark"
                :label="tooltipLabelText"
                :position="tooltipPosition"
                multilined
              >
                <div>{{ $t("subscriptions.active") }}</div>
                <div v-if="subscriptionFromDate">
                  {{ $t("subscriptions.activeFrom") }}
                  {{ subscriptionFromDate }}
                </div>
              </b-tooltip>
            </div></b-tag
          >

          <b-tag
            v-else
            @click.prevent.stop=""
            size="is-large"
            class="is-notactivesubscription default-cursor"
            rounded
            ><div class="inner-block-for-subscription-status-tag">
              {{ $t("subscriptions.notActive") }}
            </div></b-tag
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import helpers from "../helpers";

export default {
  name: "PhoneNubmerAndSubscriptionStatusBlock",
  // eslint-disable-next-line
  computed: {
    tooltipPosition() {
      if (this.screenSize.isMobile || this.screenSize.isTablet) {
        return "is-left";
      }
      return "is-bottom";
    },
    tooltipLabelText() {
      if (this.showingMustRedrawingWhenChangeLocaleElements) {
        return `${
          this.getSubscriptionById(this.currentSubscriptionsId).name
        } ${this.$t("subscriptions.activeFrom")} ${
          this.subscriptionFromDateWithMinutesAndSeconds
        }`;
      }
      return "";
    },
    currentSubscriptionCreatedAtTimestamp() {
      return this.$store.getters.getCurrentSubscriptionCreatedAtTimestamp;
    },
    currentSubscriptionsId() {
      return this.$store.getters.getCurrentSubscriptionId;
    },
    subscriptionFromDate() {
      if (
        this.currentSubscriptionCreatedAtTimestamp &&
        this.currentSubscriptionCreatedAtTimestamp !== 0
      ) {
        return moment(this.currentSubscriptionCreatedAtTimestamp * 1000).format(
          " DD.MM.YYYY  "
        );
      }
      return undefined;
    },
    subscriptionFromDateWithMinutesAndSeconds() {
      if (
        this.currentSubscriptionCreatedAtTimestamp &&
        this.currentSubscriptionCreatedAtTimestamp !== 0
      ) {
        return moment(this.currentSubscriptionCreatedAtTimestamp * 1000).format(
          " DD.MM.YYYY H:mm:ss "
        );
      }
      return undefined;
    },
    phoneNumber() {
      return this.$store.getters.getPhoneNumber;
    },
    phoneNumberMasked() {
      const phoneLength = this.phoneNumber.length;
      if (phoneLength > 0) {
        return `${this.phoneNumber.slice(0, phoneLength - 4)}****`;
      }
      return "";
    },
  },
  methods: {
    getSubscriptionById(id) {
      return helpers.getSubscriptionById(
        id,
        this.$store.getters.getSubscriptions
      );
    },
  },
};
</script>

<style scoped>
.default-cursor {
  cursor: default;
}
.inner-block-for-subscription-status-tag {
  font-size: 0.7em;
  text-align: center;
}
.is-subscription-active-inner-block-when-subscriptionFromDate {
  text-align: center;
  font-size: 0.6em;
  padding: 0.5em;
}
</style>
