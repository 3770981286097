// import ruValidator from "vee-validate/dist/locale/ru";
import ruValidator from "./validatorMessages/ru";
import momentDurationLocaleRU from "./momentDurationLocales/ru";

const locale = {
  page404: {
    titleNotFound: "Страница не найдена",
    titleNoProfileId: "Не задан ID Профиля",
    titleBadProfileId: "ID Профиля задан неверно",
    title:
      "Страница не найдена или не задан ID Профиля. Проверьте правильность URL адреса",
    currentProfileId: "Текущий ID Профиля",
    toMainPage: "На главную",
    forExampleCaption: "Адрес должен иметь следующий вид",
  },
  general: {
    noData: "Нет данных",
    changeView: "Изменить вид",
    yes: "да",
    no: "нет",
    test: "Тест",
    nextButtonCaption: "Продолжить",
    goBackButtonCaption: "Назад",
    goMainCaption: "На главную",
    date: "Дата",
    goToBannerCaption: "Перейти",
    cancel: "Отмена",
    repeatCheck: "Повторить проверку",
    hoursShort: "{hours} ч.",
    minShort: "{min} мин.",
    secShort: "{sec} сек.",
    daysShort: "{days} дн.",
  },
  formsFileds: {
    phoneNumber: "Номер телефона",
    smsCode: "Код из СМС",
    lastFourDigits: "Последние 4 цифры",
  },
  login: {
    inputPhoneNumberCaption: "Введите ваш номер телефона",
    inputCodeFromSmsCaption: "Введите код из SMS",
    repeatSendSMSCodeButtonCaption: "Получить код повторно",
    sendSMSCodeButtonCaption: "Получить код",
    sendSMSCodeAgainButtonCaption: "Получить код повторно через",
    anotherPhoneNumberButtonCaption: "Другой номер",
    notMyPhoneNumberButtonCaption: "Не мой номер",
    enterLast4digitsOfPhoneNumber:
      "Введите последние 4 цифры вашего номера телефона",
    nextButtonCaption: "Далее",
    yourPhoneNumbeCaption: "ваш номер телефона",
    youAcceptTheTermsOfThe: "Вводом кода из смс вы принимаете условия",
    offerCaption: "оферты",
    offer: "Оферта",
    smsSended: "Смс с кодом отправлено на номер {number}",
    smsSendedRepeat: "Смс с кодом повторно отправлено на номер {number}",
    smsCodeExpire:
      "Срок действия СМС кода истек. Необходимо получить его снова",
    noOfferTextForSelectedLang:
      "Барои забони ҷорӣ матни пешниҳод вуҷуд надорад",
  },
  navbar: {
    header: "Green Wi-Fi | Megafon TJ",
    logoutButtonCaption: "Выйти",
  },
  dashboard: {
    header: "Главная",
    navBarItemCaption: "Главная",
  },
  devices: {
    header: "Устройства",
    navBarItemCaption: "Устройства",
    currentDevice: "Текущее устройство",
    os: "ОС",
    type: "Тип",
    otherDevicesCaption: "Еще устройства",
  },
  sessions: {
    linkCaption: "История подключений",
    header: "История подключений",
    navBarItemCaption: "История подключений",
    startDate: "Начало",
    stopDate: "Окончание",
    deviceType: "Тип",
    ip: "IP",
    os: "ОС",
    browser: "Браузер",
    deviceDesktop: "Компьютер",
    deviceTablet: "Планшет",
    deviceMobile: "Телефон",
  },
  subscriptions: {
    header: "Подписки",
    navBarItemCaption: "Подписки",
    active: "Активна",
    notActive: "Не активна",
    activateSubscription: "Активировать подписку",
    cancelSubscription: "Отменить подписку",
    aboutSubscriptionCaption: "Подробнее о подписке на Green Wi-Fi",
    cancellationConfirmationMessage: "Вы уверены что хотите отменить подписку?",
    noSubscriptionDescription: "Нет описания",
    noSubscriptions: "Нет подписок",
    subscribed: "Подписка активирована",
    unSubscribed: "Подписка отменена",
    onlyForMegafonSubscribers: "Только для абонентов Мегафон",
    numberCheckExecuting: "Номер проверяется",
    cancellationConfirmationMessageInConfirmModal: "Отменить подписку?",
    activeFrom: "с",
    recurringPeriodCaption: "Период",
    recurringPeriodDay: "в день",
    recurringPeriodWeek: "в неделю",
    recurringPeriodMonth: "в месяц",
    price: "Стоимость",
    available: "Доступно",
  },
  errors: {
    noUser: "Пользователь с такими данными не найден",
    wrongSMSCode: "Неверный СМС код",
    accountDataLoadingError: "Ошибка при загрузке данных об аккаунте",
    sessionsDataLoadingError: "Ошибка при загрузке данных о подключениях",
    numberCheckError: "Ошибка при проверке номера",
  },
  momentDurationLocale: {
    ...momentDurationLocaleRU,
  },

  validation: ruValidator.messages,
};

export default locale;
