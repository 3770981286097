<template>
  <div>
    <b-loading
      :is-full-page="true"
      :active="isSubscribeRequestExecuting"
      :can-cancel="false"
    ></b-loading>
    <PhoneNubmerAndSubscriptionStatusBlock />
    <div class="card pb-1">
      <TitleAndBackButton class="pl-2 pt-2"></TitleAndBackButton>
      <div v-if="subscriptionsList.length">
        <b-carousel
          :icon-pack="'mdi'"
          :icon-size="
            screenSize.isMobile || screenSize.isTablet
              ? 'is-small'
              : 'is-medium'
          "
          :icon-prev="'chevron-left'"
          :icon-next="'chevron-right'"
          :arrowHover="false"
          :autoplay="false"
          :arrow="isOnlyOneSubscriptionInList ? false : true"
          :indicator="isOnlyOneSubscriptionInList ? false : true"
          :repeat="true"
          :indicator-inside="false"
          v-model="inCarouselSubscriptionNumberInArray"
        >
          <b-carousel-item
            v-for="subscription of subscriptionsList"
            v-bind:key="subscription.id"
          >
            <section>
              <section
                class="mt-2 ml-2 mr-2 hero is-small is-activesubscription"
              >
                <div class="hero-body custom-height">
                  <p class="subtitle is-4 mb-2 has-text-centered">
                    <b>{{ subscription.name }}</b>
                  </p>
                  <p
                    class="has-text-left subscription-price"
                    :class="{
                      'subscription-price-mobile':
                        screenSize.isMobile || screenSize.isTablet,
                    }"
                  >
                    <b>{{ $t("subscriptions.price") }}</b
                    >:&nbsp;<span>{{ subscription.amount }}</span>
                    <span
                      class="has-text-left"
                      v-if="isSubscriptionRecurring(subscription)"
                    >
                      {{ getRecurringPeriodWithI18n(subscription) }}</span
                    >
                  </p>
                  <p
                    class="mb-2 has-text-left subscription-length"
                    :class="{
                      'subscription-length-mobile':
                        screenSize.isMobile || screenSize.isTablet,
                    }"
                  >
                    <b>{{ $t("subscriptions.available") }}</b
                    >:&nbsp;<span class="has-text-left">{{
                      getSessionLengthTime(subscription)
                    }}</span>
                  </p>
                  <p
                    class="subtitle subscription-description"
                    :class="{
                      'subscription-description-mobile':
                        screenSize.isMobile || screenSize.isTablet,
                    }"
                  >
                    <span class="description-span-wrapper">
                      {{ getSubscriptionDescription(subscription.id) }}
                    </span>
                  </p>
                </div>
              </section>
            </section>
          </b-carousel-item>
        </b-carousel>
        <div
          class="mt-3 mb-5 has-text-centered"
          v-if="subscriptionsList.length"
        >
          <b-button
            size="is-medium"
            :disabled="
              disableSubscriptionActivateButton ||
              !inCarouselSubscriptionId ||
              currentSubscriptionId === inCarouselSubscriptionId
            "
            @click.prevent.stop="
              activateSubscriptionButtonClickHandler(inCarouselSubscriptionId)
            "
            :class="
              !disableSubscriptionActivateButton &&
              inCarouselSubscriptionId &&
              currentSubscriptionId !== inCarouselSubscriptionId
                ? 'is-activesubscription'
                : ''
            "
            >{{ $t("subscriptions.activateSubscription") }}</b-button
          >
        </div>
      </div>
      <div class="mt-2 ml-2 mr-2 hero is-small" v-else>
        {{ $t("subscriptions.noSubscriptions") }}
      </div>

      <div class="pl-2 mt-2">
        <GoToSessionsButton />
      </div>
    </div>
  </div>
</template>

<script>
import PhoneNubmerAndSubscriptionStatusBlock from "../components/PhoneNubmerAndSubscriptionStatusBlock";
import TitleAndBackButton from "../components/TitleAndBackButton";
import GoToSessionsButton from "../components/GoToSessionsButton";
// import { setIsSubscribedAndCurrrentSubscriptionId } from "../api/subscriptions";

import helpers from "../helpers";
import { subscribe } from "../api/subscriptions";
import { getAccount } from "../api/account";
// import moment from "moment";

// const RECURRING_PERIODS = { DAY: "day", WEEK: "week", MONTH: "month" };

export default {
  name: "SubscriptionActivate",
  components: {
    TitleAndBackButton,
    PhoneNubmerAndSubscriptionStatusBlock,
    GoToSessionsButton,
  },
  data() {
    return {
      inCarouselSubscriptionNumberInArray: 0,
    };
  },
  created() {
    if (!this.$store.getters.getIsMegafonNumber) {
      this.$router.push({
        name: "Dashboard",
        params: { profileIdShort: this.$store.getters.getProfileIdShort },
      });
    }
  },
  computed: {
    // recurringPeriods() {
    //   return RECURRING_PERIODS;
    // },
    isOnlyOneSubscriptionInList() {
      if (
        this.subscriptionsList.length &&
        this.subscriptionsList.length === 1
      ) {
        return true;
      }
      return false;
    },
    inCarouselSubscriptionObject() {
      // возвращаем объект подписки, которая сейчас показывается  в карусели
      if (this.subscriptionsList.length !== 0) {
        return this.subscriptionsList[this.inCarouselSubscriptionNumberInArray];
      } else {
        return undefined;
      }
    },
    inCarouselSubscriptionId() {
      // возвращаем id подписки, который показывается в карусели
      if (
        this.inCarouselSubscriptionObject !== undefined &&
        this.inCarouselSubscriptionObject.id
      ) {
        return this.inCarouselSubscriptionObject.id;
      }
      return undefined;
    },
    currentSubscriptionId() {
      return this.$store.getters.getCurrentSubscriptionId;
    },
    isSubscribeRequestExecuting() {
      return this.$store.getters.getSubscribeRequestExecuting;
    },
    disableSubscriptionActivateButton() {
      if (
        this.isSubscribed ||
        !this.isMegafonNumber ||
        this.isSubscribeRequestExecuting
      ) {
        return true;
      }
      return false;
    },
    subscriptionsList() {
      return [...this.$store.getters.getSubscriptions];
    },
    screenSize() {
      return this.$store.getters.getScreenSize;
    },
  },
  methods: {
    // setSubscribedAndCurrrentSubscriptionId(subscriptionId) {
    //   setIsSubscribedAndCurrrentSubscriptionId(subscriptionId);
    // },
    async setIsSubscribedAndCurrrentSubscriptionData() {
      // после успешного оформления подписки запрашиваем данные из /account
      // в результате этого в store заполняются поля с ID текущей подписки и с временем подписки
      await getAccount();
    },
    // handleSubscribeSuccessAnswer(response) {
    //   // console.log(response.data);
    //   const subscriptionId = response.data?.subscribe_id;
    //   this.setSubscribedAndCurrrentSubscriptionId(subscriptionId);
    //   this.$router.push({ name: "Dashboard" });
    //   this.$buefyNotifications.success(this.$t("subscriptions.subscribed"));
    // },
    // eslint-disable-next-line
    async handleSubscribeSuccessAnswer(response) {
      // console.log(response.data);
      // const subscriptionId = response.data?.subscribe_id;
      try {
        await this.setIsSubscribedAndCurrrentSubscriptionData();
        this.$router.push({ name: "Dashboard" });
        this.$buefyNotifications.success(this.$t("subscriptions.subscribed"));
      } catch (e) {
        console.log(e);
      }
    },

    handleSubscribeAnswerError(err) {
      this.$buefyNotifications.danger(
        helpers.axiosErrorAsStringForDisplay(err)
      );
    },
    activateSubscriptionButtonClickHandler(subscriptionId) {
      // console.log("click activate button");
      // console.log("subscriptionId", subscriptionId);
      subscribe(this, subscriptionId).then(
        // eslint-disable-next-line
        (response) => {
          this.handleSubscribeSuccessAnswer(response);
        },
        (err) => {
          this.handleSubscribeAnswerError(err);
        }
      );
    },
    getSubscriptionDescription(subscriptionId) {
      const subscription = this.subscriptionsList.find((subscription) => {
        return subscription.id === subscriptionId;
      });
      if (!subscription) {
        return this.$t("subscriptions.noSubscriptionDescription");
      } else {
        try {
          const locale = helpers.getLocaleNameForSubscriptionsDescription(
            this.$i18n.locale
          );
          // const description = subscription[locale];
          let description = undefined;
          if (
            subscription.description &&
            typeof subscription.description === "object" &&
            Object.hasOwnProperty.call(subscription.description, locale)
          ) {
            description = subscription.description[locale];
          }
          if (description) {
            return description;
          } else {
            return this.$t("subscriptions.noSubscriptionDescription");
          }
        } catch (e) {
          console.log(e);
          this.$t("subscriptions.noSubscriptionDescription");
        }
      }
    },
    isSubscriptionRecurring(subscription) {
      return subscription.recurring;
    },
    getRecurringPeriodWithI18n(subscription) {
      try {
        const recurringPeriodFromSubscription = subscription.recurring_period;
        const type = helpers.capitalizeFirstLetter(
          recurringPeriodFromSubscription
        );
        return this.$t(`subscriptions.recurringPeriod${type}`);
      } catch (e) {
        console.log(e);
        return subscription.recurring_period;
      }
    },
    // getSessionLengthTime(subscription) {
    //   try {
    //     const sessionFromSubscription = subscription.session; // длина сессии в секундах
    //     // console.log(moment.duration(sessionFromSubscription * 1000).format("_HMS_"))
    //     return `${moment
    //       .duration(sessionFromSubscription * 1000)
    //       .format("_HMS_")}.`;
    //   } catch (e) {
    //     console.log(e);
    //     return subscription.session;
    //   }
    // },
    getSessionLengthTime(subscription) {
      try {
        const [hours, min, sec] = helpers.getTimeInHours(subscription.session);
        const h = this.$t("general.hoursShort", { hours });
        const m = this.$t("general.minShort", { min });
        const s = this.$t("general.secShort", { sec });

        if (!subscription.session) {
          return "";
        } else {
          return `${hours ? h : ""} ${min ? m : ""} ${
            sec && hours < 1 ? s : ""
          }`;
        }
      } catch (e) {
        console.log(e);
        return subscription.session;
      }
    },
  },
};
</script>

<style scoped>
.custom-height {
  max-height: 32vh;
  min-height: 32vh;
  overflow: auto;
}
.subscription-description {
  font-size: 1rem;
  padding-right: 2.2rem;
  padding-left: 2.2rem;
}
.subscription-description-mobile {
  font-size: 0.9rem;
  padding-right: 0.7rem;
  padding-left: 0.7rem;
}

.subscription-price {
  font-size: 1rem;
  padding-right: 2.2rem;
  padding-left: 2.2rem;
  margin-bottom: 0;
}
.subscription-price-mobile {
  font-size: 0.9rem;
  padding-right: 0.7rem;
  padding-left: 0.7rem;
  margin-bottom: 0;
}

.subscription-length {
  font-size: 1rem;
  padding-right: 2.2rem;
  padding-left: 2.2rem;
  margin-top: -0.3rem;
}
.subscription-length-mobile {
  font-size: 0.9rem;
  padding-right: 0.7rem;
  padding-left: 0.7rem;
  margin-top: -0.3rem;
}
.description-span-wrapper {
  /*Чтобы всё отображалсь не в одну строку. А с разрывом на новые, если они так указаны в админке есть (\n)*/
  white-space: pre-line;
}
</style>
