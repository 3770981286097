import Vue from "vue";
import { API } from "@/envVarsAndConstants";
import store from "@/store";
// import helpers from "@/helpers";
// import { TIME_BETWEEN_NUMBER_CHECK_REQUESTS } from "@/envVarsAndConstants";
// import { getSessions } from "@/api/sessions";
// import helpers from "@/helpers";

const checkNumberApiCall = () => {
  // const payload = {
  //   username: helpers.getPhoneNumberWithoutFirstPlus(
  //     store.getters.getPhoneNumber
  //   ),
  // };
  return new Promise((resolve, reject) => {
    // const query = { action: "R" };
    Vue.axios
      .get(
        `${window.RESTAPI || ""}/${store.getters.getProfileIdFull}/${
          API.CHECK_NUMBER
        }`
        // payload
      )
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
  });
};

// const timerBetweenAPICalls = (timeBeetwin) => {
//   return new Promise((resolve, reject) => {
//     try {
//       setTimeout(() => {
//         resolve();
//       }, timeBeetwin);
//     } catch (e) {
//       reject(e);
//     }
//   });
// };

export const checkNumber = async (ctx) => {
  store.commit("setIsMegafonNumber", false);
  store.commit("setCheckNumberRequestExecuting", true);
  try {
    const responseData = await checkNumberApiCall(ctx);
    // await timerBetweenAPICalls(TIME_BETWEEN_NUMBER_CHECK_REQUESTS);
    // await checkNumberApiCall(ctx);

    // console.log(responseData)
    const isClient = responseData?.data?.is_client;
    store.commit("setIsMegafonNumber", isClient);
  } catch (e) {
    console.log("checkNumber", e);
    const checkNumberSnackbar = ctx.$buefy.snackbar.open({
      message: `${ctx.$t("errors.numberCheckError")}. ${ctx.$t(
        "general.repeatCheck"
      )}?`,
      type: "is-warning",
      indefinite: true,
      position: "is-bottom",
      cancelText: ctx.$t("general.cancel"),
      onAction: () => {
        checkNumber(ctx);
      },
    });
    store.commit("addSnackbarToOpenedSnackbarsList", checkNumberSnackbar);
    ctx.$buefyNotifications.danger(ctx.$t("errors.numberCheckError"));
  } finally {
    store.commit("setCheckNumberRequestExecuting", false);
  }
};
