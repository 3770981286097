import { render, staticRenderFns } from "./SubscriptionsView.vue?vue&type=template&id=83a18354&scoped=true&"
import script from "./SubscriptionsView.vue?vue&type=script&lang=js&"
export * from "./SubscriptionsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83a18354",
  null
  
)

export default component.exports