<template>
  <div v-if="offerEnable">
    <b-modal has-modal-card v-model="showOfferModal">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <div>
              <span class="is-size-4">{{ $t("login.offer") }}</span>
            </div>
            <div class="is-size-7 offer-text-in-modal">
              {{ textForCurrentLocale }}
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <div v-if="external || (!external && fileUrl && !fileUrl.includes('http'))">
      <span>{{ $t("login.youAcceptTheTermsOfThe") }}&nbsp;</span>
      <a
        :href="externalUrlOrFileUrl"
        target="_blank"
        role="button"
        class="has-text-secondary"
        >{{ $t("login.offerCaption") }}</a
      >
    </div>
    <div v-else>
      <span>{{ $t("login.youAcceptTheTermsOfThe") }}&nbsp;</span>
      <a
        @click="clickShowOfferHandler"
        role="button"
        class="has-text-secondary"
        >{{ $t("login.offerCaption") }}</a
      >
    </div>
  </div>
</template>

<script>
import helpers from "../helpers";

export default {
  name: "OfferBlock",
  data() {
    return {
      showOfferModal: false,
    };
  },
  methods: {
    clickShowOfferHandler() {
      this.showOfferModal = true;
    },
  },
  props: {
    offerObject: {
      type: Object,
      required: true,
    },
  },

  computed: {
    offerEnable() {
      return this.offerObject.enable;
    },
    external() {
      return this.offerObject.external;
    },
    fileUrl() {
      return this.offerObject.file_url;
    },
    text() {
      return this.offerObject.text;
    },
    externalUrlOrFileUrl() {
      if (this.external) {
        return this.fileUrl;
      }
      return `${window.SERVER_ADDRESS_FOR_TOS}${this.fileUrl}`;
    },
    textForCurrentLocale() {
      try {
        const currentLocale = helpers.getLocaleNameForOffer(this.$i18n.locale);
        const parsedText = JSON.parse(this.text);
        if (parsedText && parsedText[currentLocale]) {
          return parsedText[currentLocale];
        } else {
          // если нет для заданой локали возвращаем таджикский
          return `${this.$t("login.noOfferTextForSelectedLang")} ${
            parsedText["tg"] ? parsedText["tg"] : ""
          }`;
        }
      } catch (e) {
        console.log(e);
        return this.$t("login.noOfferTextForSelectedLang");
      }
    },
  },
  created() {
    // console.log(this.$router);
  },
};
</script>

<style scoped></style>
