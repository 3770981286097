<template>
  <div class="app">
    <Navbar></Navbar>
    <b-loading
      :is-full-page="true"
      :active="showSpinner"
      :can-cancel="false"
    ></b-loading>

    <section class="pb-0 mb-0 is-fullheight-with-navbar-custom">
      <div
        class="columns is-centered m-0 mt-0 mb-0 ml-0 mr-0 p-0 pt-0 pb-0 pl-0 pr-0 custom-padding-for-full-container-children"
      >
        <div class="column custom-max-width-for-full-container-children">
          <router-view></router-view>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import { getSubscriptions } from "../api/subscriptions";
// import { getAccount } from "../api/account";
// import { getSessions } from "../api/sessions";

//import { getAccountAndThenGetSessions } from "../api/account";
import { getAccount } from "../api/account";
import { checkNumber } from "../api/checkNumber";

export default {
  // eslint-disable-next-line
  name: "Full",
  components: { Navbar },
  data() {
    return {};
  },
  computed: {
    showSpinner() {
      return this.showOverAllAppSpinner;
    },
  },
  created() {
    // this.getProfile();
    this.getSubscriptions();
    // this.getAccount();
    // this.getSessions();
    // this.getAccountAndThenGetSessions();
    this.getAccount();
    this.checkNumber();
  },
  methods: {
    handleSuccessProfileResponse(profile) {
      this.$store.commit("setProfile", profile);
    },
    getSubscriptions() {
      getSubscriptions(this);
    },
    // getAccount() {
    //   getAccount(this);
    // },
    // getSessions() {
    //   getSessions(this);
    // },
    // getAccountAndThenGetSessions() {
    //   getAccountAndThenGetSessions(this);
    // },
    getAccount() {
      getAccount(this);
    },
    checkNumber() {
      checkNumber(this);
    },
  },
};
</script>

<style scoped>
/*.main-section{*/
/*  height: calc(100vh - 3.25rem) ;*/
/*}*/
.is-fullheight-with-navbar-custom {
  min-height: calc(100%);
}
.custom-max-width-for-full-container-children {
  max-width: 700px;
}
.custom-padding-for-full-container-children {
  padding-top: 3.25rem !important;
}
</style>
