const momentDurationLocaleTJ = {
  durationLabelsStandard: {
    S: "миллисония",
    SS: "миллисонияҳо",
    s: "сония",
    ss: "сония",
    m: "дақиқа",
    mm: "дақиқа",
    h: "соат",
    hh: "соат",
    d: "рӯз",
    dd: "рӯз",
    w: "як ҳафта",
    ww: "як ҳафта",
    M: "моҳ",
    MM: "моҳ",
    y: "соли",
    yy: "соли",
  },
  durationLabelsShort: {
    S: "мс",
    SS: "мс",
    s: "сон",
    ss: "сон",
    m: "дақ",
    mm: "дақ",
    h: "с",
    hh: "с",
    d: "р",
    dd: "р",
    w: "х",
    ww: "х",
    M: "м",
    MM: "м",
    y: "с",
    yy: "с",
  },
  durationTimeTemplates: {
    // HMS: "h:mm:ss",
    HMS: "h соат, m дақ. ss сон.",
    HM: "h:mm",
    MS: "m дақ ss сон",
  },
  durationLabelTypes: [
    { type: "standard", string: "__" },
    { type: "short", string: "_" },
  ],
  durationPluralKey: function (token, integerValue, decimalValue) {
    // Singular for a value of `1`, but not for `1.0`.
    if (integerValue === 1 && decimalValue === null) {
      return token;
    }

    return token + token;
  },
};
export default momentDurationLocaleTJ;
