import store from "@/store";

export const setVueMixins = (vueInstance) => {
  vueInstance.mixin({
    computed: {
      // при смене локализации изменяется true-false->true
      // чтобы глядя на этот computed перерисовывать \ пересчитывать эдементы, где локаль  не меняется реактивно
      // например выведеные через moment даты
      showingMustRedrawingWhenChangeLocaleElements() {
        return store.getters.getShowMustRedrawingElements;
      },
      isSubscribed() {
        return store.getters.getIsSubscribed;
      },
      isMegafonNumber() {
        return store.getters.getIsMegafonNumber;
      },
      screenSize() {
        return store.getters.getScreenSize;
      },
      showOverAllAppSpinner() {
        // отвечает за отображение основного спинера в Full.vue
        // также сюда смотрят остальные спинеры, которые отображаются над отдельными элементами и не отображаются
        // когда отображается основной
        // чтобы на страничке не было по несколько спинеров
        if (
          store.getters.getGetSubscriptionsRequestExecuting ||
          store.getters.getLogoutRequestExecuting ||
          store.getters.getAccountRequestExecuting
          // this.$store.getters.getSessionsRequestExecuting
        ) {
          return true;
        }
        return false;
      },
    },
  });
};
