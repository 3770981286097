const customValidationRules = {
  testRule: (value) => {
    if (value >= 0) {
      return true;
    } else {
      return false;
    }
  },
  phoneNumber: (value) => {
    try {
      const digitsRegex = new RegExp("^\\d+$");
      return digitsRegex.test(value.slice(1)) && value[0] === "+";
    } catch (e) {
      console.error(e);
      return true;
    }
  },
  smsCode: {
    validate(value, { length }) {
      try {
        const digitsRegex = new RegExp("^\\d+$");
        const valueLength = value.length;
        // console.log("value", value);
        // console.log("length", length);
        // console.log('valueLength', valueLength)
        // console.log("digitsRegex.test(value)", digitsRegex.test(value));
        // console.log("valueLength === length;", valueLength == length);
        return digitsRegex.test(value) && valueLength === parseInt(length);
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    params: ["length"],
  },
  lastFourDigits: {
    validate(value, { fullPhoneNumber }) {
      try {
        const phoneLength = fullPhoneNumber.length;
        const realLastFourDigits = fullPhoneNumber.slice(phoneLength - 4);
        const enteredLastFourDigits = value;
        // console.log('enteredLastFourDigits', enteredLastFourDigits)
        // console.log('realLastFourDigits', realLastFourDigits)
        return parseInt(realLastFourDigits) === parseInt(enteredLastFourDigits);
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    params: ["fullPhoneNumber"],
  },
};

export default customValidationRules;
