import { NotificationProgrammatic as Notification } from "buefy";

export default {
  // info(ctx, message, position, duration) {
  info(message, position, duration) {
    const params = {
      message: message.toString(),
      type: "is-info",
      hasIcon: true,
    };
    if (duration !== undefined) {
      params.duration = duration;
    }
    if (position !== undefined) {
      params.position = position;
    }
    // ctx.$buefy.notification.open(params);
    Notification.open(params);
  },
  // success(ctx, message, position, duration) {
  success(message, position, duration) {
    const params = {
      message: message.toString(),
      type: "is-success",
      hasIcon: true,
    };
    if (duration !== undefined) {
      params.duration = duration;
    }
    if (position !== undefined) {
      params.position = position;
    }
    // ctx.$buefy.notification.open(params);
    Notification.open(params);
  },
  // warning(ctx, message, position, duration) {
  warning(message, position, duration) {
    const params = {
      message: message.toString(),
      type: "is-warning",
      hasIcon: true,
    };
    if (duration !== undefined) {
      params.duration = duration;
    }
    if (position !== undefined) {
      params.position = position;
    }
    // ctx.$buefy.notification.open(params);
    Notification.open(params);
  },
  // danger(ctx, message, position, duration) {
  danger(message, position, duration) {
    const params = {
      message: message.toString(),
      type: "is-danger",
      hasIcon: true,
    };
    if (duration !== undefined) {
      params.duration = duration;
    }
    if (position !== undefined) {
      params.position = position;
    }
    // ctx.$buefy.notification.open(params);
    Notification.open(params);
  },
  custom(params) {
    // ctx.$buefy.notification.open(params);
    Notification.open(params);
  },
};
