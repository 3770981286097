<template>
  <div>
    <PhoneNubmerAndSubscriptionStatusBlock />
    <div class="card pb-1 sessions-table-wrapper">
      <TitleAndBackButton class="pl-2 pt-2">{{
        $t("sessions.header")
      }}</TitleAndBackButton>
      <div
        class="pl-2 pt-2"
        v-if="this.screenSize.isMobile || this.screenSize.isTablet"
      >
        <b-switch size="is-small" v-model="mobileCards">{{
          $t("general.changeView")
        }}</b-switch>
      </div>
      <div class="columns is-mobile m-0 pb-0">
        <div class="column is-12 m-0 p-0">
          <b-table
            :loading="isSessionsLoading && !showOverAllAppSpinner"
            class="sessions-table-custom"
            v-if="showingMustRedrawingWhenChangeLocaleElements"
            :mobile-cards="mobileCards"
            :default-sort="['create_at', 'asc']"
            striped
            hoverable
            :data="sessionsData"
            :columns="sessionsTableColumns"
          >
            <template slot="empty">
              <div class="is-fullwidth has-text-centered">
                {{ $t("general.noData") }}
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PhoneNubmerAndSubscriptionStatusBlock from "../components/PhoneNubmerAndSubscriptionStatusBlock";
import TitleAndBackButton from "../components/TitleAndBackButton";
import { getSessions } from "../api/sessions";
import helpers from "../helpers";

export default {
  name: "SessionsView",
  components: { PhoneNubmerAndSubscriptionStatusBlock, TitleAndBackButton },
  data() {
    return {
      mobileCards: false,
      isMobile: false,
    };
  },
  created() {
    this.getSessions();
  },
  methods: {
    getSessions() {
      getSessions(this);
    },
    extractOSFromSession(session) {
      const ua = session.ua;
      if (ua) {
        const os = ua.os ? ua.os : "";
        const osVer = ua.os_version ? ua.os_version : "";
        return `${os} ${osVer}`;
      } else "";
    },
    extractBrowserFromSession(session) {
      const ua = session.ua;
      if (ua) {
        const browser = ua.name ? ua.name : "";
        return `${browser}`;
      } else "";
    },
    extractTypeFromSession(session) {
      const ua = session.ua;
      if (ua) {
        const type = ua.type ? ua.type : "";
        return `${type}`;
      } else "";
    },
    typeCaptionWithi18n(type) {
      if (type === "desktop") {
        return this.$t("sessions.deviceDesktop");
      }
      if (type === "tablet") {
        return this.$t("sessions.deviceTablet");
      }
      if (type === "mobile") {
        return this.$t("sessions.deviceMobile");
      }

      return type;
    },
  },
  computed: {
    isSessionsLoading() {
      return this.$store.getters.getSessionsRequestExecuting;
    },
    sessionsFromAPI() {
      if (this.$store.getters.getSessions) {
        return this.$store.getters.getSessions;
      } else {
        return [];
      }
    },
    sessionsTableColumns() {
      // чтобы перерисовывалось при сменен локали
      if (this.showingMustRedrawingWhenChangeLocaleElements) {
        return [
          {
            field: "mac",
            label: "MAC",
            centered: true,
          },
          {
            field: "create_at",
            label: this.$t("sessions.startDate"),
            centered: true,
            sortable: true,
          },
          {
            field: "stop_at",
            label: this.$t("sessions.stopDate"),
            centered: true,
            sortable: true,
          },
          {
            field: "ip",
            label: this.$t("sessions.ip"),
            centered: true,
            sortable: true,
          },
          {
            field: "type",
            label: this.$t("sessions.deviceType"),
            centered: true,
            sortable: true,
          },
          {
            field: "os",
            label: this.$t("sessions.os"),
            centered: true,
            sortable: true,
          },
          {
            field: "browser",
            label: this.$t("sessions.browser"),
            centered: true,
            sortable: true,
          },
        ];
      } else {
        return [];
      }
    },
    sessionsData() {
      // чтобы перерисовывалось при сменен локали
      if (this.showingMustRedrawingWhenChangeLocaleElements) {
        return this.sessionsFromAPI.map((session) => {
          return {
            mac: session.mac ? helpers.getFormatedMAC(session.mac) : "",
            create_at: session.create_at
              ? moment(session.create_at * 1000).format(" DD.MM.YYYY H:mm  ")
              : "",
            stop_at: session.stop_at
              ? moment(session.stop_at * 1000).format(" DD.MM.YYYY H:mm  ")
              : "",
            ip: session.ip ? session.ip : "",
            type: this.typeCaptionWithi18n(
              this.extractTypeFromSession(session)
            ),
            os: this.extractOSFromSession(session),
            browser: this.extractBrowserFromSession(session),
          };
        });
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped>
.sessions-table-custom {
  font-size: 0.85em;
  /*margin: 0;*/
  /*padding: 0;*/
  /*border: 0;*/
}
/*.sessions-table-wrapper {*/
/*  overflow-x: auto;*/
/*}*/
</style>
