import Vue from "vue";
import Vuex from "vuex";
import { CODE_LENGTH } from "../envVarsAndConstants";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    account: {},
    profile: {},
    sessions: [],
    profileIdShort: "",
    phoneNumber: "",
    subscriptions: [],
    // Храним тут объекты открытых Snackbar'ов чтобы звкрыть их путем вызова .close. Например при логауте
    openedSnackbars: [],
    smsCodeLength: CODE_LENGTH,
    isSubscribed: false,
    currentSubscriptionId: "",
    currentSubscriptionCreatedAtTimestamp: 0,
    isMegafonNumber: false,
    localeSwitchedTimeStamp: Date.now,
    // некотрые элементы, например  вывод moment не перерисовыываются налету, когда меняется локаль
    // в таких случаях они смотрят сюда и перерисовываются принудатильнно при смене true->false->true;
    showMustRedrawingElements: true,
    requestsExecuting: {
      checkAuth: false,
      getProfile: false,
      subscriptions: false,
      logout: false,
      account: false,
      sessions: false,
      subscribe: false,
      unSubscribe: false,
      checkNumber: false,
    },
    screenSize: {},
  },
  getters: {
    getProfile(state) {
      return state.profile;
    },
    getAccount(state) {
      return state.account;
    },
    getAccountMacs(state) {
      try {
        if (state.account?.macs) {
          return state.account.macs;
        }
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    getCurrentSubscriptionId(state) {
      return state.currentSubscriptionId;
    },
    getAccountId(state) {
      return state.account.id || "";
    },
    getOfferFromProfile(state) {
      if (
        state.profile.terms_of_service &&
        Object.keys(state.profile.terms_of_service).length > 0
      ) {
        return state.profile.terms_of_service;
      }
      return undefined;
    },
    getProfileIdFull(state) {
      if (
        Object.prototype.hasOwnProperty.call(state.profile, "id") &&
        state.profile.id !== undefined &&
        state.profile.id !== null
      ) {
        return state.profile.id;
      }
      return undefined;
    },
    getSubscriptions(state) {
      return state.subscriptions;
    },
    getSessions(state) {
      return state.sessions;
    },
    getShowMustRedrawingElements(state) {
      return state.showMustRedrawingElements;
    },
    getLocaleSwitchedTimeStamp(state) {
      return state.localeSwitchedTimeStamp;
    },
    getCheckAuthRequestExecuting(state) {
      return state.requestsExecuting.checkAuth;
    },
    getSubscribeRequestExecuting(state) {
      return state.requestsExecuting.subscribe;
    },
    getUnSubscribeRequestExecuting(state) {
      return state.requestsExecuting.unSubscribe;
    },
    getCurrentSubscriptionCreatedAtTimestamp(state) {
      return state.currentSubscriptionCreatedAtTimestamp;
    },
    getGetProfileRequestExecuting(state) {
      return state.requestsExecuting.getProfile;
    },
    getCheckNumberRequestExecuting(state) {
      return state.requestsExecuting.checkNumber;
    },
    getAccountRequestExecuting(state) {
      return state.requestsExecuting.account;
    },
    getSessionsRequestExecuting(state) {
      return state.requestsExecuting.sessions;
    },
    getGetSubscriptionsRequestExecuting(state) {
      return state.requestsExecuting.subscriptions;
    },
    getLogoutRequestExecuting(state) {
      return state.requestsExecuting.logout;
    },
    getPhoneNumber(state) {
      return state.phoneNumber;
    },
    // getPhoneNumberWithoutFirstPlus(state) {
    //   if (state.phoneNumber.length && state.phoneNumber.length > 1) {
    //     // console.log(state.phoneNumber.slice(1))
    //     if (Array.from(state.phoneNumber)[0] === "+") {
    //       return state.phoneNumber.slice(1);
    //     }
    //   }
    //   return state.phoneNumber;
    // },
    getIsSubscribed(state) {
      return state.isSubscribed;
    },
    getIsMegafonNumber(state) {
      return state.isMegafonNumber;
    },
    getSmsCodeLength(state) {
      return state.smsCodeLength;
    },
    getProfileIdShort(state) {
      return state.profileIdShort;
    },
    getScreenSize(state) {
      return state.screenSize;
    },
  },
  mutations: {
    setLocaleSwitchedTimeStamp(state) {
      // обновляется при смене локали в LocaleSwitcher'e
      // обновляем текущим таймстампом, просто потому что не нужно придумывать какие-либо другие счетички
      // главное чтобы менялось какое-нибуь значение, чтобы следить за ним с помощью
      // вотчера в App.vue и перерисовывать все не делая reload страницы
      state.localeSwitchedTimeStamp = Date.now();
    },
    setAccount(state, accountObject) {
      state.account = JSON.parse(JSON.stringify(accountObject));
    },
    setPhoneNumber(state, value) {
      state.phoneNumber = value;
    },
    setIsSubscribed(state, value) {
      state.isSubscribed = value;
    },
    setSmsCodeLength(state, value) {
      state.smsCodeLength = value;
    },
    clearPhoneNumber(state) {
      state.phoneNumber = "";
    },
    setProfileIdShort(state, value) {
      state.profileIdShort = value;
    },
    clearProfileIdShort(state) {
      state.profileIdShort = "";
    },
    setSubscriptions(state, arrayOfSubscriptions) {
      state.subscriptions = JSON.parse(JSON.stringify(arrayOfSubscriptions));
    },
    clearSubscriptions(state) {
      state.subscriptions = [];
    },
    setSessions(state, sessionsArray) {
      state.sessions = JSON.parse(JSON.stringify(sessionsArray));
    },
    setCheckAuthRequestExecuting(state, value) {
      state.requestsExecuting.checkAuth = value;
    },
    setSubscribeRequestExecuting(state, value) {
      state.requestsExecuting.subscribe = value;
    },
    setUnSubscribeRequestExecuting(state, value) {
      state.requestsExecuting.unSubscribe = value;
    },
    setAccountRequestExecuting(state, value) {
      state.requestsExecuting.account = value;
    },
    setCheckNumberRequestExecuting(state, value) {
      state.requestsExecuting.checkNumber = value;
    },
    setSessionsRequestExecuting(state, value) {
      state.requestsExecuting.sessions = value;
    },
    setGetSubscriptionsRequestExecuting(state, value) {
      state.requestsExecuting.subscriptions = value;
    },
    setGetProfileRequestExecuting(state, value) {
      state.requestsExecuting.getProfile = value;
    },
    setLogoutRequestExecuting(state, value) {
      state.requestsExecuting.logout = value;
    },
    setProfile(state, profileObject) {
      state.profile = JSON.parse(JSON.stringify(profileObject));
    },
    setRedrawMustRedrawingElementsToTrue(state) {
      state.showMustRedrawingElements = true;
    },
    setCurrentSubscriptionCreatedAtTimestamp(state, value) {
      state.currentSubscriptionCreatedAtTimestamp = value;
    },
    clearCurrentSubscriptionCreatedAtTimestamp(state) {
      state.currentSubscriptionCreatedAtTimestamp = 0;
    },
    setCurrentSubscriptionId(state, value) {
      state.currentSubscriptionId = value;
    },
    setRedrawMustRedrawingElementsToFalse(state) {
      state.showMustRedrawingElements = false;
    },
    setIsMegafonNumber(state, value) {
      state.isMegafonNumber = value;
    },
    setScreenSize(state, value) {
      state.screenSize = value;
    },
    addSnackbarToOpenedSnackbarsList(state, value) {
      state.openedSnackbars.push(value);
    },
    closeOpenedSnackbarsAndClearOpenedSnackbarsList(state) {
      try {
        const openedSnackbars = state.openedSnackbars;
        openedSnackbars.forEach((snackbar) => {
          snackbar.close();
        });
      } catch (e) {
        console.log(e);
      } finally {
        state.openedSnackbars = [];
      }
    },
  },
  actions: {
    redrawMustRedrawingElements(context) {
      // console.log("redrawMustRedrawingElements!!!");
      context.commit("setRedrawMustRedrawingElementsToFalse");
      Vue.nextTick(() => {
        context.commit("setRedrawMustRedrawingElementsToTrue");
      });
    },
  },
  modules: {},
});
