import Vue from "vue";
import { API } from "@/envVarsAndConstants";
import store from "@/store";
import helpers from "@/helpers";

export const setIsSubscribedAndCurrrentSubscriptionId = (subscribtionId) => {
  if (subscribtionId !== "" && subscribtionId !== undefined) {
    store.commit("setIsSubscribed", true);
    store.commit("setCurrentSubscriptionId", subscribtionId);
  } else {
    store.commit("setIsSubscribed", false);
    store.commit("setCurrentSubscriptionId", "");
  }
};

export const setIsUnsubscribedAndClearSubscriptionData = () => {
  store.commit("setIsSubscribed", false);
  store.commit("setCurrentSubscriptionId", "");
  store.commit("clearCurrentSubscriptionCreatedAtTimestamp");
};

export const setIsSubscribedAndCurrrentSubscriptionData = (
  subscribtionId,
  createdAtTimestamp
) => {
  if (subscribtionId !== "" && subscribtionId !== undefined) {
    store.commit("setIsSubscribed", true);
    store.commit("setCurrentSubscriptionId", subscribtionId);
    store.commit(
      "setCurrentSubscriptionCreatedAtTimestamp",
      createdAtTimestamp
    );
  } else {
    setIsUnsubscribedAndClearSubscriptionData();
  }
};

export const getSubscriptions = (ctx) => {
  store.commit("setGetSubscriptionsRequestExecuting", true);
  Vue.axios
    .get(
      `${window.RESTAPI || ""}/${store.getters.getProfileIdFull}/${
        API.SUBSCRIPTIONS
      }`
    )
    .then(
      (response) => {
        // console.log(response);
        store.commit("setGetSubscriptionsRequestExecuting", false);
        const subscriptionsFromAPI = response.data.subscriptions;
        store.commit("setSubscriptions", subscriptionsFromAPI);
      },
      (err) => {
        store.commit("setGetSubscriptionsRequestExecuting", false);
        console.log(err);
        // ctx.$buefyNotifications.danger(err.message);
        ctx.$buefyNotifications.danger(
          helpers.axiosErrorAsStringForDisplay(err)
        );
      }
    );
};

export const subscribe = (ctx, subscriptionId) => {
  return new Promise((resolve, reject) => {
    store.commit("setSubscribeRequestExecuting", true);
    const payload = {
      subscribe_id: subscriptionId,
    };
    Vue.axios
      .post(
        `${window.RESTAPI || ""}/${store.getters.getProfileIdFull}/${
          API.SUBSCRIBE
        }`,
        payload
      )
      .then(
        (response) => {
          store.commit("setSubscribeRequestExecuting", false);
          resolve(response);
        },
        (err) => {
          store.commit("setSubscribeRequestExecuting", false);
          reject(err);
        }
      );
  });
};

export const unSubscribe = (ctx, subscriptionId) => {
  return new Promise((resolve, reject) => {
    store.commit("setUnSubscribeRequestExecuting", true);
    const payload = {
      subscribe_id: subscriptionId,
    };
    Vue.axios
      .post(
        `${window.RESTAPI || ""}/${store.getters.getProfileIdFull}/${
          API.UNSUBSCRIBE
        }`,
        payload
      )
      .then(
        (response) => {
          store.commit("setUnSubscribeRequestExecuting", false);
          resolve(response);
        },
        (err) => {
          store.commit("setUnSubscribeRequestExecuting", false);
          reject(err);
        }
      );
  });
};
