<template>
  <div>
    <b-button
      type="is-text"
      @click="$router.push({ name: 'Sessions' })"
      class="custom-button"
    >
      {{ $t("sessions.linkCaption") }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: "GoToSessionsButton",
  methods: {},

  commputed: {},
  created() {
    // console.log(this.$router);
  },
};
</script>

<style scoped>
.custom-button {
  border: 0;
  padding-left: 0.2em;
  padding-right: 0.2em;
  margin: 0;
}
</style>
