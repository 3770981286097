// import enValidator from "vee-validate/dist/locale/en";

import enValidator from "./validatorMessages/en";
import momentDurationLocaleEN from "./momentDurationLocales/en";

const locale = {
  page404: {
    titleNotFound: "Page not found",
    titleNoProfileId: "Profile ID not set",
    titleBadProfileId: "Profile ID is incorrect",
    title: "Page not found. Check if the URL is correct",
    currentProfileId: "Current Profile ID",
    toMainPage: "To main page",
    forExampleCaption: "The address should look like this",
  },
  general: {
    noData: "No data",
    changeView: "Сhange view",
    yes: "yes",
    no: "no",
    test: "Test",
    nextButtonCaption: "Next",
    goBackButtonCaption: "Back",
    goMainCaption: "Go to main page",
    date: "Date",
    goToBannerCaption: "Go",
    cancel: "Сancel",
    repeatCheck: "Repeat check",
    hoursShort: "{hours} h.",
    minShort: "{min} min.",
    secShort: "{sec} sec.",
    daysShort: "{days} d.",
  },
  formsFileds: {
    phoneNumber: "Phone number",
    smsCode: "Code from SMS",
    lastFourDigits: "Last 4 digits",
  },
  login: {
    inputPhoneNumberCaption: "Enter your phone number",
    inputCodeFromSmsCaption: "Enter code from SMS",
    repeatSendSMSCodeButtonCaption: "Get the code",
    sendSMSCodeAgainButtonCaption: "Get the code again",
    sendSMSCodeButtonCaption: "get sms code",
    anotherPhoneNumberButtonCaption: "Another number",
    notMyPhoneNumberButtonCaption: "Not my number",
    enterLast4digitsOfPhoneNumber:
      "Enter the last 4 digits of your phone number",
    nextButtonCaption: "Next",
    yourPhoneNumbeCaption: "your phone number",
    youAcceptTheTermsOfThe:
      "By entering the code from SMS you accept the terms of the",
    offerCaption: "offer",
    offer: "Offer",
    smsSended: "SMS with code sent to number {number}",
    smsSendedRepeat: "SMS with code resent to number {number}",
    smsCodeExpire: "The SMS code has expired. Need to get it again",
    noOfferTextForSelectedLang:
      "There is no offer text for the current language",
  },
  navbar: {
    header: "Green Wi-Fi | Megafon TJ",
    logoutButtonCaption: "Log out",
  },
  dashboard: {
    header: "Main page",
    navBarItemCaption: "Main page",
  },
  devices: {
    header: "Devices",
    navBarItemCaption: "Devices",
    currentDevice: "Current device",
    os: "OS",
    type: "Type",
    otherDevicesCaption: "Other devices",
  },
  sessions: {
    linkCaption: "Connections history",
    header: "Connections history",
    navBarItemCaption: "Connections history",
    startDate: "Start",
    stopDate: "Stop",
    deviceType: "Type",
    ip: "IP",
    os: "OS",
    browser: "Browser",
    deviceDesktop: "Desktop",
    deviceTablet: "Tablet",
    deviceMobile: "Mobile",
  },
  subscriptions: {
    header: "Subscriptions",
    navBarItemCaption: "Subscriptions",
    active: "Active",
    notActive: "Not Active",
    activateSubscription: "Activate subscription",
    cancelSubscription: "Cancel Subscription",
    aboutSubscriptionCaption: "About Subscriptions on Green Wi-Fi",
    cancellationConfirmationMessage:
      "Are you sure you want to cancel your subscription?",
    noSubscriptionDescription: "No description",
    noSubscriptions: "No subscriptions",
    subscribed: "Subscription activated",
    unSubscribed: "Subscription canceled",
    onlyForMegafonSubscribers: "Only for Megafon subscribers",
    numberCheckExecuting: "Number Check Executing",
    cancellationConfirmationMessageInConfirmModal: "Cancel subscription?",
    activeFrom: "from",
    recurringPeriodCaption: "Period",
    recurringPeriodDay: "per day",
    recurringPeriodWeek: "per week",
    recurringPeriodMonth: "per month",
    price: "Price",
    available: "Available",
  },
  errors: {
    noUser: "User with such data was not found",
    wrongSMSCode: "Invalid SMS code",
    accountDataLoadingError: "Error loading account information",
    sessionsDataLoadingError: "Error loading connections data",
    numberCheckError: "Number validation error",
  },

  momentDurationLocale: {
    ...momentDurationLocaleEN,
  },

  validation: enValidator.messages,
};

export default locale;
