export const API = {
  LOGIN: "login",
  LOGOUT: "logout",
  APPLY_SIGN_IN: "apply_sign_in",
  ACCOUNT: "account",
  CHECK_AUTH: "account",
  PROFILE: "profile",
  SUBSCRIPTIONS: "subscriptions",
  REPEAT_OTP: "repeated_otp",
  SESSIONS: "sessions",
  SUBSCRIBE: "subscribe",
  UNSUBSCRIBE: "unsubscribe ",
  CHECK_NUMBER: "check_user",
};

export const LOCALE = "locale"; //название поля в localStorage
export const TOKEN = "token"; //название поля в localStorage
export const PHONE_NUMBER = "phoneNumber"; //название поля в localStorage
export const SEND_AGAIN_CODE_TIMESTAMP = "sendAgainCodeTimestamp"; //имя для сохранние под ним в local storage timestam'a для таймера повторого отправления СМС кода
export const SEND_AGAIN_CODE_INTERVAL = 3 * 60 * 1000; // время, на которое дизейблится кнопка повторного запроса отправки смс кода (три минуты в миллисекундах)
export const CODE_LENGTH = 4; // длинна смс кода
export const TIME_BETWEEN_NUMBER_CHECK_REQUESTS = 5 * 1000; // таймер между вызовами функций для проверки номер на то мегафон это или нет

const DEFAULT_LOCALE_IF_NO_ENV_LOCALE = "tj"; // локаль по умолчанию tj, ru, en (если иное не задано в переменных окружения)
const LOCALE_FROM_ENV = process.env.VUE_APP_LOCALE || "";

// console.log(window.location.pathname);

export const extractProfileIdShortFromURL = () => {
  try {
    // извелкаем из url short profile id
    // url вида cabinet.wi-fi.tj/324fdf/
    // вытаскиваем /324fdf/
    let profileShortId;
    const pathname = window.location.pathname;
    const firstPathFromUrl = pathname.split("/")[1];
    // console.log(firstPathFromUrl);
    profileShortId = firstPathFromUrl;
    return profileShortId || "";
  } catch (e) {
    console.error(e);
    return "";
  }
};
const PROFILE_ID_SHORT = extractProfileIdShortFromURL();

const RESTAPI_FROM_ENV = process.env.VUE_APP_RESTAPI;
const RESTAPI_DEFAULT = `${window.location.protocol}//${window.location.hostname}/api`;

const SERVER_ADDRESS_FOR_TOS_FROM_ENV =
  process.env.VUE_APP_SERVER_ADDRESS_FOR_TOS;
const SERVER_ADDRESS_FOR_TOS_DEFAULT = RESTAPI_DEFAULT;

// далее устанавливаем значения переменных в window

export const setEnviroment = () => {
  // Setting API HOST for building with environment variables => "VUE_APP_RESTAPI='host.com/' vue-cli-service serve"
  window.RESTAPI = RESTAPI_FROM_ENV || RESTAPI_DEFAULT;
  window.LOCALE =
    LOCALE_FROM_ENV.toLowerCase() || DEFAULT_LOCALE_IF_NO_ENV_LOCALE;
  window.PROFILE_ID_SHORT = PROFILE_ID_SHORT;
  window.SERVER_ADDRESS_FOR_TOS =
    SERVER_ADDRESS_FOR_TOS_FROM_ENV || SERVER_ADDRESS_FOR_TOS_DEFAULT;
  window.linkForAboutBanner = process.env.VUE_APP_LINK_FOR_ABOUT_BANNER;
  console.log(process.env);
  console.log(window);
};
