<template>
  <div
    class="is-fullwidth is-flex-direction-column is-justify-content-start is-align-items-start"
  >
    <div class="is-size-5">{{ $t("devices.currentDevice") }}</div>
    <div class="is-size-6">MAC: {{ getFormatedMAC(currentDeviceMAC) }}</div>
    <!--                          <div class="is-size-6">MAC: Android 11</div>-->
    <!--                      <div class="is-size-6">{{ $t("devices.type") }}: mobile</div>-->
  </div>
</template>

<script>
import helpers from "../helpers";

export default {
  name: "CurrentDeviceBlock",
  data() {
    return {
      showOfferModal: false,
    };
  },
  methods: {
    clickShowOfferHandler() {
      this.showOfferModal = true;
    },
    getFormatedMAC(mac) {
      return helpers.getFormatedMAC(mac);
    },
  },
  props: {
    currentDevice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    currentDeviceComputed() {
      return this.currentDevice;
    },
    currentDeviceMAC() {
      return this.currentDeviceComputed.mac;
    },
  },
  created() {
    // console.log(this.$router);
  },
};
</script>

<style scoped></style>
