import {
  SEND_AGAIN_CODE_TIMESTAMP,
  PHONE_NUMBER,
  TOKEN,
  LOCALE,
} from "./envVarsAndConstants";
import helpers from "@/helpers";
export default {
  sendAgainCode: {
    saveSendAgainCodeTimestampToLocalStorage(valueForSave) {
      localStorage.setItem(SEND_AGAIN_CODE_TIMESTAMP, valueForSave);
    },
    loadSendAgainCodeTimestampFromLocalStorage() {
      return localStorage.getItem(SEND_AGAIN_CODE_TIMESTAMP);
    },
    clearSendAgainCodeTimestampInLocalStorage() {
      localStorage.removeItem(SEND_AGAIN_CODE_TIMESTAMP);
    },
  },
  phone: {
    savePhoneToLocalStorage(valueForSave) {
      localStorage.setItem(PHONE_NUMBER, valueForSave);
    },
    loadPhoneFromLocalStorage() {
      return localStorage.getItem(PHONE_NUMBER);
    },
    loadPhoneWithoutPlusFromLocalStorage() {
      return helpers.getPhoneNumberWithoutFirstPlus(
        localStorage.getItem(PHONE_NUMBER)
      );
    },
    clearPhoneInLocalStorage() {
      localStorage.removeItem(PHONE_NUMBER);
    },
  },
  token: {
    saveTokenToLocalStorage(valueForSave) {
      localStorage.setItem(TOKEN, valueForSave);
    },
    loadTokenFromLocalStorage() {
      return localStorage.getItem(TOKEN);
    },
    clearTokenInLocalStorage() {
      localStorage.removeItem(TOKEN);
    },
  },
  locale: {
    saveLocaleToLocalStorage(valueForSave) {
      localStorage.setItem(LOCALE, valueForSave);
    },
    loadLocaleFromLocalStorage() {
      return localStorage.getItem(LOCALE);
    },
    clearLocaleInLocalStorage() {
      localStorage.removeItem(LOCALE);
    },
  },
};
