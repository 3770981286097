// import ruValidator from "vee-validate/dist/locale/ru";
import tjValidator from "./validatorMessages/tj";
import momentDurationLocaleTJ from "./momentDurationLocales/tj";

const locale = {
  page404: {
    titleNotFound: "Саҳифа ёфт нашуд",
    titleNoProfileId: "ID профил муқаррар карда нашудааст",
    titleBadProfileId: "ID профил нодуруст аст",
    title: "Саҳифа ёфт нашуд. Санҷед, ки оё URL дуруст аст",
    currentProfileId: "ID профили ҷорӣ",
    toMainPage: "Ба саҳифаи асосӣ",
    forExampleCaption: "Адрес бояд чунин бошад",
  },
  general: {
    noData: "Маълумот нест",
    changeView: "Тағир додани назар",
    yes: "бале",
    no: "не",
    test: "озмоиш",
    nextButtonCaption: "идома диҳед",
    goBackButtonCaption: "баргашт",
    goMainCaption: "Ба саҳифаи асосӣ гузаред",
    date: "Сана",
    goToBannerCaption: "Равед",
    cancel: "Бекор кардан",
    repeatCheck: "Санҷиши такрорӣ",
    hoursShort: "{hours} соат",
    minShort: "{min} дақ.",
    secShort: "{sec} сон.",
    daysShort: "{days} рӯз.",
  },
  formsFileds: {
    phoneNumber: "Рақами мобилӣ",
    smsCode: "Рамз аз SMS",
    lastFourDigits: "4 рақами охирин",
  },
  login: {
    inputPhoneNumberCaption: "Рақами телефони худро ворид кунед",
    inputCodeFromSmsCaption: "Рамзро аз SMS ворид кунед",
    repeatSendSMSCodeButtonCaption: "Рамзро гиред",
    sendSMSCodeAgainButtonCaption: "Рамзро боз гиред",
    sendSMSCodeButtonCaption: "рамзи SMS гиред",
    anotherPhoneNumberButtonCaption: "Рақами дигар",
    notMyPhoneNumberButtonCaption: "Рақами ман нест",
    enterLast4digitsOfPhoneNumber:
      "4 рақами охирини рақами телефони худро ворид кунед",
    nextButtonCaption: "Баъдӣ",
    yourPhoneNumbeCaption: "рақами телефони шумо",
    youAcceptTheTermsOfThe:
      "Бо ворид кардани код аз SMS шумо шартҳои пешниҳодро қабул мекунед",
    offerCaption: "пешниҳод",
    offer: "Пешниҳод",
    smsSended: "SMS бо код ба рақами {number} фиристода шуд",
    smsSendedRepeat: "SMS бо код ба рақами {number} дубора фиристода шуд",
    smsCodeExpire: "Рамзи SMS гузаштааст. Боз гирифтан лозим аст",
    noOfferTextForSelectedLang:
      "There is no offer text for the current language",
  },
  navbar: {
    header: "Green Wi-Fi | Megafon TJ",
    logoutButtonCaption: "Баромадан",
  },
  dashboard: {
    header: "Саҳифаи асосӣ",
    navBarItemCaption: "Саҳифаи асосӣ",
  },
  devices: {
    header: "Дастгоҳҳо",
    navBarItemCaption: "Дастгоҳҳо",
    currentDevice: "Дастгоҳи ҷорӣ",
    os: "OS",
    type: "Навъи",
    otherDevicesCaption: "Дигар дастгоҳҳо",
  },
  sessions: {
    linkCaption: "Таърихи пайвастшавӣ",
    header: "Таърихи пайвастшавӣ",
    navBarItemCaption: "Таърихи пайвастшавӣ",
    startDate: "Оғоз",
    stopDate: "Анҷом",
    deviceType: "Навъи",
    ip: "IP",
    os: "НА",
    browser: "Браузер",
    deviceDesktop: "Компютер",
    deviceTablet: "Планшет",
    deviceMobile: "Телефон",
  },
  subscriptions: {
    header: "Обунаҳо",
    navBarItemCaption: "Обунаҳо",
    active: "Фаъол",
    notActive: "Фаъол нест",
    activateSubscription: "Обунаро фаъол созед",
    cancelSubscription: "Обунаро бекор кунед",
    aboutSubscriptionCaption: "Тафсилоти обуна Green Wi-Fi",
    cancellationConfirmationMessage:
      "Шумо мутмаин ҳастед, ки мехоҳед обунаро қатъ кунед?",
    noSubscriptionDescription: "Тавсиф нест",
    noSubscriptions: "Обуна нест",
    subscribed: "Обуна фаъол карда шуд",
    unSubscribed: "Обуна бекор карда шуд",
    onlyForMegafonSubscribers: "Танҳо барои муштариёни Мегафон",
    numberCheckExecuting: "Номер проверяется",
    cancellationConfirmationMessageInConfirmModal: "Бекор кардан?",
    activeFrom: "аз",
    recurringPeriodCaption: "Давра",
    recurringPeriodDay: "дар як рӯз",
    recurringPeriodWeek: "дар як ҳафта",
    recurringPeriodMonth: "дар як моҳ",
    price: "Нарх",
    available: "Дастрас",
  },

  errors: {
    noUser: "Корбаре бо чунин маълумот ёфт нашуд",
    wrongSMSCode: "Рамзи SMS нодуруст",
    accountDataLoadingError: "Хатогӣ ҳангоми боркунии маълумоти ҳисоб",
    sessionsDataLoadingError: "Хатогӣ ҳангоми боркунии маълумоти пайвастшавӣ",
    numberCheckError: "Хатогии тасдиқи рақам",
  },

  momentDurationLocale: {
    ...momentDurationLocaleTJ,
  },

  validation: tjValidator.messages,
};

export default locale;
