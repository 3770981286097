import moment from "moment";
import localeEN from "./locales/en";
import localeRU from "./locales/ru";
import localeTJ from "./locales/tj";

const changeMomentDurationLocale = (localeName) => {
  try {
    if (localeName.toLowerCase() === "tj") {
      moment.updateLocale("tg", { ...localeTJ.momentDurationLocale });
      // console.log("changeMomentLocale set", "tg");
    } else if (localeName.toLowerCase() === "en") {
      moment.updateLocale("en-gb", { ...localeEN.momentDurationLocale });
      // console.log("changeMomentLocale set", "en-gb");
    } else if (localeName.toLowerCase() === "ru") {
      moment.updateLocale("ru", { ...localeRU.momentDurationLocale });
    } else {
      moment.updateLocale("en", { ...localeEN.momentDurationLocale });
    }
  } catch (e) {
    console.error(e);
  }
};

export default {
  changeMomentLocale(localeName) {
    try {
      // console.log("changeMomentLocale input", localeName);
      if (localeName.toLowerCase() === "tj") {
        moment.locale("tg");
        changeMomentDurationLocale(localeName);
        // console.log("changeMomentLocale set", "tg");
      } else if (localeName.toLowerCase() === "en") {
        moment.locale("en-gb");
        changeMomentDurationLocale(localeName);
        // console.log("changeMomentLocale set", "en-gb");
      } else if (localeName.toLowerCase() === "ru") {
        moment.locale("ru");
        changeMomentDurationLocale(localeName);
        // console.log("changeMomentLocale set", "ru");
      } else {
        changeMomentDurationLocale("en");
        moment.locale(localeName.toLowerCase());
      }
    } catch (e) {
      console.error(e);
    }
  },
  axiosErrorAsStringForDisplay(axiosErr) {
    // return `${axiosErr.request.responseURL} ${axiosErr.response.status}  ${axiosErr.response.statusText}`;
    return axiosErr.message;
  },
  getPhoneNumberWithoutFirstPlus(phoneNumber) {
    if (phoneNumber.length && phoneNumber.length > 1) {
      // console.log(state.phoneNumber.slice(1))
      if (Array.from(phoneNumber)[0] === "+") {
        return phoneNumber.slice(1);
      }
    }
    return phoneNumber;
  },
  getLocaleNameForSubscriptionsDescriptionAndOffer(frontLocale) {
    if (frontLocale === "ru") {
      return "ru";
    } else if (frontLocale === "en") {
      return "en";
    } else if (frontLocale === "tj") {
      return "tg";
    }
    return frontLocale;
  },
  getLocaleNameForSubscriptionsDescription(frontLocale) {
    return this.getLocaleNameForSubscriptionsDescriptionAndOffer(frontLocale);
  },
  getLocaleNameForOffer(frontLocale) {
    return this.getLocaleNameForSubscriptionsDescriptionAndOffer(frontLocale);
  },
  getFormatedMAC(mac) {
    if (mac.length === 12) {
      return mac
        .replace(/(.{2})/g, "$1:")
        .slice(0, -1)
        .toUpperCase();
    }
    if (mac) {
      return mac.toUpperCase();
    }
    return "";
  },
  setScreenSizeToStore(storeInstance) {
    storeInstance.commit("setScreenSize", {
      isMobile: screen.width <= 768,
      isTablet: screen.width >= 769 && screen.width <= 1023,
      isDesktop: screen.width >= 1024 && screen.width <= 1215,
      isWideScreen: screen.width <= 1216 && screen.width <= 1407,
      isFullHD: screen.width >= 1408,
      screenWidth: screen.width,
      windowInnerWidth: window.innerWidth,
    });
  },
  startScreenSizeWatching(storeInstance) {
    window.addEventListener("resize", () => {
      this.setScreenSizeToStore(storeInstance);
    });
    this.setScreenSizeToStore(storeInstance);
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  getSubscriptionById(subscriptionId, subscriptionsArray) {
    if (subscriptionId) {
      const subscription = subscriptionsArray.find((subscription) => {
        return subscription.id === subscriptionId;
      });
      return subscription;
    }
    return undefined;
  },
  getTimeInHours(value) {
    // на вход ждем количество секунд
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - hours * 3600) / 60);
    const seconds = (value - (hours * 3600 + minutes * 60)) % 60;

    return [hours, minutes, seconds];
  },

  getTimeInDays(value) {
    // на вход ждем количество секунд
    const days = Math.floor(value / 86400);
    const hours = Math.floor((value - days * 86400) / 3600);
    const minutes = Math.floor((value - (hours * 3600 + days * 86400)) / 60);
    const seconds = (value - (hours * 3600 + minutes * 60 + days * 86400)) % 60;
    return [days, hours, minutes, seconds];
  },
};
