import { render, staticRenderFns } from "./LastFourDigitsLogin.vue?vue&type=template&id=2bf4f5b8&scoped=true&"
import script from "./LastFourDigitsLogin.vue?vue&type=script&lang=js&"
export * from "./LastFourDigitsLogin.vue?vue&type=script&lang=js&"
import style0 from "./LastFourDigitsLogin.vue?vue&type=style&index=0&id=2bf4f5b8&scoped=true&lang=css&"
import style1 from "./LastFourDigitsLogin.vue?vue&type=style&index=1&lang=css&"
import style2 from "./LastFourDigitsLogin.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bf4f5b8",
  null
  
)

export default component.exports