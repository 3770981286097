<template>
  <div>
    <b-loading
      :is-full-page="true"
      :active="isUnSubscribeRequestExecuting"
      :can-cancel="false"
    ></b-loading>
    <PhoneNubmerAndSubscriptionStatusBlock />
    <div class="card pb-1">
      <TitleAndBackButton class="pl-2 pt-2"></TitleAndBackButton>
      <section class="mt-2 ml-2 mr-2 hero is-small has-text-centered">
        <div class="hero-body custom-height">
          <p class="subtitle is-3">
            {{ $t("subscriptions.cancellationConfirmationMessage") }}
          </p>
        </div>
      </section>
      <div class="mt-3 mb-3 has-text-centered">
        <b-button
          size="is-medium"
          :disabled="disableSubscriptionCancelButton"
          @click.prevent.stop="cancelConfirmModal"
          :class="
            !disableSubscriptionCancelButton ? 'is-notactivesubscription' : ''
          "
          >{{ $t("subscriptions.cancelSubscription") }}</b-button
        >
      </div>
      <div class="pl-2 mt-2">
        <GoToSessionsButton />
      </div>
    </div>
  </div>
</template>

<script>
import PhoneNubmerAndSubscriptionStatusBlock from "../components/PhoneNubmerAndSubscriptionStatusBlock";
import TitleAndBackButton from "../components/TitleAndBackButton";
import GoToSessionsButton from "../components/GoToSessionsButton";
// import { setIsSubscribedAndCurrrentSubscriptionId } from "../api/subscriptions";
import { setIsUnsubscribedAndClearSubscriptionData } from "../api/subscriptions";
import helpers from "../helpers";
import { unSubscribe } from "../api/subscriptions";
export default {
  name: "SubscriptionActivate",
  components: {
    TitleAndBackButton,
    PhoneNubmerAndSubscriptionStatusBlock,
    GoToSessionsButton,
  },
  computed: {
    currentSubscriptionId() {
      return this.$store.getters.getCurrentSubscriptionId;
    },
    isUnSubscribeRequestExecuting() {
      return this.$store.getters.getUnSubscribeRequestExecuting;
    },
    disableSubscriptionCancelButton() {
      if (!this.isSubscribed || !this.isMegafonNumber) {
        return true;
      }
      return false;
    },
  },
  created() {
    if (!this.$store.getters.getIsMegafonNumber) {
      this.$router.push({
        name: "Dashboard",
        params: { profileIdShort: this.$store.getters.getProfileIdShort },
      });
    }
  },
  methods: {
    cancelConfirmModal() {
      this.$buefy.dialog.confirm({
        type: "is-notactivesubscription",
        message: this.$t(
          "subscriptions.cancellationConfirmationMessageInConfirmModal"
        ),
        cancelText: helpers.capitalizeFirstLetter(this.$t("general.no")),
        confirmText: helpers.capitalizeFirstLetter(this.$t("general.yes")),
        onConfirm: () => this.cancelSubscriptionButtonClickHandler(),
      });
    },
    // clearSubscribedAndCurrrentSubscriptionId() {
    //   setIsSubscribedAndCurrrentSubscriptionId();
    // },
    clearSubscribedAndCurrrentSubscriptionData() {
      setIsUnsubscribedAndClearSubscriptionData();
    },
    handleUnSubscribeSuccessAnswer() {
      this.$buefyNotifications.success(this.$t("subscriptions.unSubscribed"));
      // this.clearSubscribedAndCurrrentSubscriptionId();
      this.clearSubscribedAndCurrrentSubscriptionData();
      this.$router.push({ name: "Dashboard" });
    },
    handleUnSubscribeAnswerError(err) {
      this.$buefyNotifications.danger(
        helpers.axiosErrorAsStringForDisplay(err)
      );
    },
    cancelSubscriptionButtonClickHandler() {
      // console.log("click cancel button");
      const subscriptionId = this.currentSubscriptionId;
      unSubscribe(this, subscriptionId).then(
        // eslint-disable-next-line
        (response) => {
          this.handleUnSubscribeSuccessAnswer();
        },
        (err) => {
          this.handleUnSubscribeAnswerError(err);
        }
      );
    },
  },
};
</script>

<style scoped>
.custom-height {
  max-height: 50vh;
  min-height: 30vh;
  overflow: auto;
}
</style>
