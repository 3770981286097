import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// eslint-disable-next-line
import moment from "moment";
// eslint-disable-next-line
import momentFormat from "moment-duration-format";
import helpers from "@/helpers";
import Buefy from "buefy";
// import "buefy/dist/buefy.css";
import "./assets/css/styles.scss";
import "@mdi/font/css/materialdesignicons.css";
import VueI18n from "vue-i18n";
import axios from "axios";
import VueAxios from "vue-axios";
import { setEnviroment } from "./envVarsAndConstants";
import buefyNotifications from "./buefyNotifications";
import { setAxiosInterceptors } from "@/axiosInterceptors";
import { setVueMixins } from "@/vueMixins";
import localeEN from "./locales/en";
import localeRU from "./locales/ru";
import localeTJ from "./locales/tj";
import localStorageHelpers from "@/localStorageHelpers";
const messages = {
  en: localeEN,
  ru: localeRU,
  tj: localeTJ,
};

import {
  ValidationObserver,
  ValidationProvider,
  configure,
  extend,
} from "vee-validate/dist/vee-validate.full.esm";

import customValidationRules from "./customValidationRules";
Object.keys(customValidationRules).forEach((customRuleName) => {
  extend(customRuleName, customValidationRules[customRuleName]);
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

configure({
  defaultMessage: (field, values) => {
    //  values._field_ = i18n.t(`fieldsNamesForValidator.${field}`);
    return i18n.t(`validation.${values._rule_}`, values);
  },
});

setEnviroment();

// const lsLOCALE = localStorage.getItem("LOCALE");
const lsLOCALE = localStorageHelpers.locale.loadLocaleFromLocalStorage();
if (lsLOCALE && lsLOCALE !== window.LOCALE) {
  window.LOCALE = lsLOCALE;
} else if (!lsLOCALE && window.LOCALE) {
  // localStorage.setItem("LOCALE", window.LOCALE);
  localStorageHelpers.locale.saveLocaleToLocalStorage(window.LOCALE);
}

// уставливаем локаль в пакете moment
helpers.changeMomentLocale(window.LOCALE);

// setEnviroment();

Vue.use(Buefy);
Vue.use(VueI18n);
Vue.use(VueAxios, axios);

const i18n = new VueI18n({
  locale: window.LOCALE,
  messages, // set locale messages
});

Vue.prototype.$buefyNotifications = buefyNotifications;

Vue.config.productionTip = false;

setAxiosInterceptors(axios);
setVueMixins(Vue);
helpers.startScreenSizeWatching(store);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
