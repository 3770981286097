<template>
  <div class="app">
    <Navbar :show-menu-items-and-logout-button="false"></Navbar>
    <b-loading
      :is-full-page="true"
      :active="loginRequestExecuting"
      :can-cancel="false"
    ></b-loading>
    <section class="pl-2 pr-2">
      <div class="columns m-0 pb-0">
        <div class="column mb-0 mt-0 pb-0 pt-0 is-half is-offset-one-quarter">
          <section class="hero">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <section
                class="hero p-0 m-0 b-0 is-fullheight-with-navbar is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center"
              >
                <div class="fields-in-login-from">
                  <ValidationProvider
                    mode="lazy"
                    rules="required|phoneNumber"
                    :name="$t('formsFileds.phoneNumber')"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      expanded
                      custom-class="custom-class-for-form-field-on-login-page"
                      :label="$t('login.inputPhoneNumberCaption')"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-input
                        size="is-medium"
                        type="tel"
                        inputmode="tel"
                        autocomplete="tel"
                        v-model="phoneNumber"
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="buttons-in-login-from">
                  <b-button
                    expanded
                    type="is-secondary"
                    @click="handleSubmit(submit)"
                  >
                    <span>{{ $t("general.nextButtonCaption") }}</span>
                  </b-button>
                </div>
              </section>
            </ValidationObserver>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import auth from "../auth/auth";
import helpers from "../helpers";
import localStorageHelpers from "../localStorageHelpers";

export default {
  // eslint-disable-next-line
  name: "Login",
  components: { Navbar },

  data() {
    return {
      isSpinnerShow: false,
      phoneNumber: "",
      error: "",
      loginRequestExecuting: false,
    };
  },
  methods: {
    handleLoginSuccessAnswer(token) {
      this.$store.commit("setPhoneNumber", this.phoneNumber);
      localStorageHelpers.phone.savePhoneToLocalStorage(this.phoneNumber);
      // localStorage.setItem("token", token);
      localStorageHelpers.token.saveTokenToLocalStorage(token);
      this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;

      this.$router.push({
        name: "Dashboard",
        params: { profileIdShort: this.$store.getters.getProfileIdShort },
      });
    },
    handleLoginNeedSMSAnswer() {
      this.$store.commit("setPhoneNumber", this.phoneNumber);
      this.$buefyNotifications.success(
        this.$t("login.smsSended", { number: this.phoneNumber })
      );
      localStorageHelpers.phone.savePhoneToLocalStorage(this.phoneNumber);
      this.$router.push({
        name: "SMSCodeEnter",
        params: { profileIdShort: this.$store.getters.getProfileIdShort },
      });
    },

    handleLoginAnswerError(err) {
      // console.log(err);
      if (err.response.status === 404) {
        this.$buefyNotifications.warning(this.$t("errors.noUser"));
        return;
      }
      // this.$buefyNotifications.danger(err.message);
      this.$buefyNotifications.danger(
        helpers.axiosErrorAsStringForDisplay(err)
      );
    },

    getPhoneNumberWithoutFirstPlus(phoneNumber) {
      return helpers.getPhoneNumberWithoutFirstPlus(phoneNumber);
    },
    submit() {
      console.log("submited data:");
      const credentials = {
        user_name: this.getPhoneNumberWithoutFirstPlus(this.phoneNumber),
        // profileID: this.$store.getters.getProfileIdShort,
      };
      console.log(credentials);
      this.loginRequestExecuting = true;
      auth.login(this, credentials).then(
        (response) => {
          //console.log('response', response)
          if (response.status === 204) {
            this.handleLoginNeedSMSAnswer();
          } else {
            this.handleLoginSuccessAnswer(response.data.token);
          }
          this.loginRequestExecuting = false;
        },
        (err) => {
          // this.$router.push({
          //   name: "Dashboard",
          //   params: { profileIdShort: this.$store.getters.getProfileIdShort },
          // });
          this.loginRequestExecuting = false;
          this.handleLoginAnswerError(err);
        }
      );
    },
  },
  computed: {},
  created() {},
};
</script>

<style scoped>
/*.custom-min-height-for-login-page {*/
/*  min-height: calc(100vh - 5rem);*/
/*  position: relative;*/
/*}*/
.buttons-in-login-from {
  position: relative;
  bottom: 70px;
  width: 100%;
  max-width: 300px;
}
.fields-in-login-from {
  position: relative;
  top: 30vh;
  width: 100%;
  max-width: 300px;
}
</style>
<style>
.custom-class-for-form-field-on-login-page {
  text-align: center;
}
</style>
