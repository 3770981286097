const momentDurationLocaleRU = {
  durationLabelsStandard: {
    S: "миллисекунда",
    SS: "миллисекунд",
    s: "секунда",
    ss: "секунд",
    m: "минута",
    mm: "минут",
    h: "час",
    hh: "часов",
    d: "день",
    dd: "дней",
    w: "неделя",
    ww: "недель",
    M: "месяц",
    MM: "месяцев",
    y: "год",
    yy: "лет",
  },
  durationLabelsShort: {
    S: "мсек",
    SS: "мсек",
    s: "сек",
    ss: "сек",
    m: "мин",
    mm: "мин",
    h: "ч",
    hh: "ч",
    d: "дн",
    dd: "дн",
    w: "нед",
    ww: "нед",
    M: "мес",
    MM: "мес",
    y: "год",
    yy: "лет",
  },
  durationTimeTemplates: {
    // HMS: "h:mm:ss",
    HMS: "h ч. m мин. ss сек.",
    HM: "h:mm",
    MS: "m мин ss сек",
  },
  durationLabelTypes: [
    { type: "standard", string: "__" },
    { type: "short", string: "_" },
  ],
  durationPluralKey: function (token, integerValue, decimalValue) {
    // Singular for a value of `1`, but not for `1.0`.
    if (integerValue === 1 && decimalValue === null) {
      return token;
    }

    return token + token;
  },
};
export default momentDurationLocaleRU;
